export enum Route {
  all = '*',
  id = ':id',
  code = ':code',
  root = '/',
  notFound = '404',
  login = 'login',
  register = 'register',
  datacentral = 'datacentral',
  customer = 'customer',
  audition = 'audition',
  electroniccommerce = 'electroniccommerce',
  authority = 'authority',
  report = 'report',
  teachersmanagement = 'teachersmanagement',
  teachersinterviewmanagement = 'teachersinterviewmanagement',
  teacherunverifiedmanagement = 'teacherunverifiedmanagement',
  financial = 'financial',
  badgecentral = 'badgecentral',
  tutorialcenter = 'tutorialcenter',
  serverError = '500',
  permissionAbnormal = 'permissionabnormal',
  sendbirdteacher = 'sendbirdteacher',
  sendbirdcustomer = 'sendbirdcustomer',
  advertisement = 'advertisement',
  customerticket = 'customerticket',
  oneAnswer = 'oneanswer',
  notification = 'notification',
  lineChatManagement = 'linechatmanagement',
  lineChat = 'linechat',
  display = 'display',
}

export const RouteName = {
  all: '全部',
  root: 'oneClub',
  notFound: '找不到',
  login: '登入',
  register: '註冊',
  datacentral: '數據中心',
  customer: '會員管理',
  audition: '課務管理',
  electroniccommerce: '電子商務',
  authority: '權限管理',
  report: '統計報表',
  teachersmanagement: '教務管理',
  teachersinterviewmanagement: '教師面試',
  teacherunverifiedmanagement: '未驗證教師列表',
  financial: '財務管理',
  serverError: '伺服器錯誤',
  badgecentral: '出貨管理',
  customerticket: '會員案件管理',
  tutorialcenter: '補教課程管理',
  sendbirdteacher: '教師聊天室',
  sendbirdcustomer: '會員訊息頻道管理',
  advertisement: '廣告管理',
  oneanswer: 'OneAnswer管理',
  referrersList: '推薦人列表',
  notification: '通知中心',
  linechat: 'Line 聊天室',
};

export enum FirstRoute {
  customerlist = 'customerlist',
  visitorlist = 'visitorlist',
  customer = 'customer',
  period = 'period',
  reservation = 'reservation',
  vipPass = 'vipPass',
  course = 'course',
  coursereview = 'coursereview',
  coursetag = 'coursetag',
  nowcourse = 'nowcourse',
  order = 'order',
  orderlist = 'orderlist',
  orderdetails = 'orderdetails',
  commodity = 'commodity',
  setting = 'setting',
  employee = 'employee',
  role = 'role',
  department = 'department',
  daily = 'daily',
  realtime = 'realtime',
  realtimecourse = 'realtimecourse',
  realtimecoursecarousel = 'realtimecoursecarousel',
  omscourse = 'omscourse',
  performance = 'performance',
  teacherlist = 'teacherlist',
  teacherinterviewlist = 'teacherinterviewlist',
  teacherunverifiedlist = 'teacherunverifiedlist',
  teachermaterial = 'teachermaterial',
  teacherApplication = 'teacherApplication',
  teacherApplicationUnverified = 'teacherApplicationUnverified',
  teacherContract = 'teacherContract',
  publicCourse = 'publicCourse',
  trialcourse = 'trialcourse',
  salary = 'salary',
  salaryoverview = 'salaryoverview',
  batchscheduledcourse = 'batchscheduledcourse',
  unearnedrevenue = 'unearnedrevenue',
  laborcost = 'laborcost',
  performanceexpense = 'performanceexpense',
  refundAssistance = 'refundAssistance',
  rewardshipmentmanagement = 'rewardshipmentmanagement',
  instantcase = 'instantcase',
  mycase = 'mycase',
  historycase = 'historycase',
  notificationcenter = 'notificationcenter',
  memberchannelmanagement = 'memberchannelmanagement',
  salesdata = 'salesdata',
  financialdata = 'financialdata',
  learningbar = 'learningbar',
  extended = 'extended',
  now = 'now',
  textbookmangement = 'textbookmangement',
  liveapp = 'liveapp',
  contract = 'contract',
  record = 'record',
  referrersList = 'referrersList',
  sendbirdcustomermanagement = 'sendbirdcustomermanagement',
  sendbirdcustomernewticket = 'sendbirdcustomernewticket',
  sendbirdcustomertickethistory = 'sendbirdcustomertickethistory',
  leave = 'leave',
  lineChat = 'linechat',
  courseclaim = 'courseclaim',
  learningCoach = 'learningCoach',
}

export const FirstRouteName = {
  customerlist: '會員列表',
  visitorlist: '聯絡人列表',
  period: '試聽時段管理',
  reservation: '試聽預約',
  vipPass: '快速通道',
  course: '一般課程管理',
  nowcourse: 'OneClass Now 課程管理',
  trialcourse: '試聽課程管理',
  coursetag: '課程標籤',
  order: '訂單',
  orderlist: '訂單列表',
  orderdetails: '訂單詳情',
  commodity: '商品',
  setting: '設定',
  employee: '人員管理',
  role: '角色設定',
  department: '部門設定',
  daily: '業務日報',
  realtime: '即時業績',
  realtimecourse: '即時觀課管理',
  realtimecoursecarousel: '即時觀課輪播',
  omscourse: 'OMS課程查詢',
  performance: '業績瀏覽',
  teacherlist: '教師列表',
  teacherinterviewlist: '教師面試',
  teacherunverifiedlist: '未驗證教師列表',
  teachermaterial: '教材資源',
  teacherApplication: '教師面試',
  teacherApplicationUnverified: '未驗證教師列表',
  teacherContract: '合約',
  publicCourse: '課外學習管理',
  salary: '教師薪資',
  salaryoverview: '薪資總覽',
  batchscheduledcourse: '連續課程管理',
  laborcost: '勞務成本表',
  refundAssistance: '退款協助',
  rewardshipmentmanagement: '獎品出貨管理',
  instantcase: '即時案件',
  mycase: '我的案件',
  historycase: '歷史案件',
  memberchannelmanagement: '會員訊息頻道管理',
  unearnedrevenue: '預收收入表',
  performanceexpense: '業務成本表',
  salesdata: '銷售數據',
  financialdata: '財務數據',
  learningbar: '學霸訂單',
  extended: '展延訂單',
  now: '小堂數訂單',
  textbookmangement: '講義出貨管理',
  coursereview: '異常完課待審核',
  liveapp: 'Live App',
  contract: '教師合約',
  record: '問答紀錄',
  referrersList: '推薦人列表',
  notificationcenter: '通知中心',
  sendbirdcustomermanagement: '家長聊天室',
  sendbirdcustomernewticket: '建立新案件',
  sendbirdcustomertickethistory: '案件紀錄',
  leave: '請假管理',
  linechat: 'Line 聊天室',
  courseclaim: '教師搶課管理',
  learningCoach: '學習教練',
};

export enum SecondRoute {
  spu = 'spu',
  sku = 'sku',
  plan = 'plan',
  create = 'create',
  read = 'read',
  edit = 'edit',
  copy = 'copy',
  promotion = 'promotion',
  payment = 'payment',
  contract = 'contract',
  mail = 'mail',
  extensioncontract = 'extensioncontract',
  learningbarcontract = 'learningbarcontract',
  tutorialcontract = 'tutorialcontract',
  tutorialvipcontract = 'tutorialvipcontract',
  tos = 'tos',
  schedule = 'schedule',
  taglist = 'taglist',
  salarydetail = 'salarydetail',
  unearnedrevenuedetail = 'unearnedrevenuedetail',
  feedback = 'feedback',
  customerdetail = ':customerId',
  seriesread = 'seriesread',
  seriescreate = 'seriescreate',
  seriesedit = 'seriesedit',
  examedit = 'examedit',
  homeworkedit = 'homeworkedit',
  applicantView = 'applicantView',
  resourcecreate = 'resourcecreate',
  resourceedit = 'resourceedit',
  visitorprocessed = 'visitorprocessed',
  performancedetail = ':performanceId',
  rewardmerchandise = 'rewardmerchandise',
  reviewdetail = 'reviewdetail',
  ordertutorial = 'ordertutorial',
  orderschool = 'orderschool',
  orderlearningbar = 'orderlearningbar',
  orderextended = 'orderextended', // 展延訂單
  noworder = 'noworder', // 小堂數訂單
  view = 'view',
  teacherContract = 'teacherContract',
  terminatedContract = 'terminatedContract',
  onelinkplus = 'onelinkplus',
  memberchanneldetail = 'memberchanneldetail',
  learningPlan = 'learningPlan',
  formal = 'formal',
  trial = 'trial',
  interactivelearningread = 'interactivelearningread',
  interactivelearningcreate = 'interactivelearningcreate',
  interactivelearningedit = 'interactivelearningedit',
}

export const SecondRouteName = {
  spu: '商品',
  sku: 'sku',
  plan: '方案',
  create: '新增',
  read: '查看',
  edit: '編輯',
  copy: '新增',
  promotion: '促銷',
  payment: '金流',
  contract: '合約',
  mail: '通知信',
  extensioncontract: '展延合約',
  learningbarcontract: '學Bar合約',
  tutorialcontract: '補習班合約',
  tutorialvipcontract: '補習班 VIP 合約',
  tos: '服務條款',
  order: '訂單',
  schedule: '行程',
  taglist: '教學風格標籤',
  salarydetail: '薪資明細',
  feedback: '課後回饋',
  customerdetail: '會員詳情',
  seriesread: '查看叢書',
  seriescreate: '新增叢書',
  seriesedit: '編輯叢書',
  resourcecreate: '新增叢書教材',
  resourceedit: '編輯叢書教材',
  examedit: '編輯測驗',
  homeworkedit: '編輯作業',
  visitorprocessed: '待處理區',
  performancedetail: '業績詳情',
  rewardmerchandise: '編輯兌換紀錄',
  unearnedrevenuedetail: '預收收入明細',
  reviewdetail: '審核詳情',
  ordertutorial: '補習班訂單',
  orderschool: '學校訂單',
  orderlearningbar: '學Bar訂單',
  orderextended: '展延訂單',
  noworder: '小堂數訂單',
  view: '查看',
  teacherContract: 'Employment contract',
  terminatedContract: 'Terminated contract',
  onelinkplus: 'OneLink+',
  memberchanneldetail: '案件紀錄',
  learningPlan: '學習計畫',
  formal: '正式課',
  trial: '試聽課',
  interactivelearningread: '查看互動教材',
  interactivelearningcreate: '新增互動教材',
  interactivelearningedit: '編輯互動教材',
};

export enum ThirdRoute {
  attribute = 'attribute',
  create = 'create',
  edit = 'edit',
  read = 'read',
  copy = 'copy',
  studentcreate = 'studentcreate',
  studentread = 'studentread',
  studentedit = 'studentedit',
  customseriescreate = 'customseriescreate',
  customseriesedit = 'customseriesedit',
}

export const ThirdRouteName = {
  attribute: '屬性管理',
  create: '新增',
  edit: '編輯',
  read: '查看',
  copy: '新增',
  studentcreate: '新增成員',
  studentread: '查看成員',
  studentedit: '編輯成員',
  customseriescreate: '新增客製叢書',
  customseriesedit: '編輯客製叢書',
};
const path = (path?: Array<string>) => {
  return path ? `${Route.root}${path.join('/')}` : Route.root;
};

export default {
  ROOT: path(),
  SERVER_ERROR: path([Route.serverError]),
  NOT_FOUND: path([Route.notFound]),
  PERMISSION_ABNORMAL: path([Route.permissionAbnormal]),
  LOGIN: path([Route.login]),
  REGISTER: path([Route.register]),
  DATA_CENTRAL: path([Route.datacentral]),
  SENDBIRD_TEACHER: path([Route.sendbirdteacher]),
  SENDBIRD_CUSTOMER: path([Route.sendbirdcustomer]),
  DATA_CENTER_SALES_DATA: path([Route.datacentral, FirstRoute.salesdata]),
  DATA_CENTER_FINANCIAL_DATA: path([
    Route.datacentral,
    FirstRoute.financialdata,
  ]),
  CUSTOMER: path([Route.customer]),
  CUSTOMER_LIST: path([Route.customer, FirstRoute.customerlist]),
  VISITOR_LIST: path([Route.customer, FirstRoute.visitorlist]),
  CUSTOMER_CREATE: path([
    Route.customer,
    FirstRoute.customerlist,
    SecondRoute.create,
  ]),
  CUSTOMER_DETAIL: path([
    Route.customer,
    FirstRoute.customerlist,
    SecondRoute.customerdetail,
  ]),
  CUSTOMER_EDIT: path([
    Route.customer,
    FirstRoute.customerlist,
    SecondRoute.customerdetail,
    ThirdRoute.edit,
  ]),
  CUSTOMER_STUDENT_CREATE: path([
    Route.customer,
    FirstRoute.customerlist,
    SecondRoute.customerdetail,
    ThirdRoute.studentcreate,
  ]),
  CUSTOMER_STUDENT_READ: path([
    Route.customer,
    FirstRoute.customerlist,
    SecondRoute.customerdetail,
    ThirdRoute.studentread,
  ]),
  CUSTOMER_STUDENT_EDIT: path([
    Route.customer,
    FirstRoute.customerlist,
    SecondRoute.customerdetail,
    ThirdRoute.studentedit,
  ]),
  CUSTOMER_CUSTOME_SERIES_CREATE: path([
    Route.customer,
    FirstRoute.customerlist,
    SecondRoute.customerdetail,
    ThirdRoute.customseriescreate,
  ]),
  CUSTOMER_CUSTOME_SERIES_EDIT: path([
    Route.customer,
    FirstRoute.customerlist,
    SecondRoute.customerdetail,
    ThirdRoute.customseriesedit,
  ]),
  CUSTOMER_VISITOR_LIST: path([Route.customer, FirstRoute.visitorlist]),
  CUSTOMER_VISITOR_LIST_VISITORPROCESSED: path([
    Route.customer,
    FirstRoute.visitorlist,
    SecondRoute.visitorprocessed,
  ]),
  AUDITION: path([Route.audition]),
  AUDITION_PERIOD: path([Route.audition, FirstRoute.period]),
  AUDITION_RESERVATION: path([Route.audition, FirstRoute.reservation]),
  AUDITION_LEARNING_COACH: path([Route.audition, FirstRoute.learningCoach]),
  AUDITION_LEARNING_PLAN: path([
    Route.audition,
    FirstRoute.learningCoach,
    SecondRoute.learningPlan,
  ]),
  AUDITION_VIP_PASS: path([Route.audition, FirstRoute.vipPass]),
  AUDITION_TRIAL_COURSE: path([Route.audition, FirstRoute.trialcourse]),
  AUDITION_TRIAL_COURSE_READ: path([
    Route.audition,
    FirstRoute.trialcourse,
    SecondRoute.read,
  ]),
  AUDITION_COURSE: path([Route.audition, FirstRoute.course]),
  AUDITION_COURSE_CREATE: path([
    Route.audition,
    FirstRoute.course,
    SecondRoute.create,
  ]),
  AUDITION_COURSE_COPY: path([
    Route.audition,
    FirstRoute.course,
    SecondRoute.copy,
  ]),
  AUDITION_COURSE_READ: path([
    Route.audition,
    FirstRoute.course,
    SecondRoute.read,
  ]),
  AUDITION_COURSE_EDIT: path([
    Route.audition,
    FirstRoute.course,
    SecondRoute.edit,
  ]),
  AUDITION_COURSE_CLAIM: path([Route.audition, FirstRoute.courseclaim]),
  AUDITION_COURSE_CLAIM_FORMAL_CREATE: path([
    Route.audition,
    FirstRoute.courseclaim,
    SecondRoute.formal,
    ThirdRoute.create,
  ]),
  AUDITION_COURSE_CLAIM_FORMAL_READ: path([
    Route.audition,
    FirstRoute.courseclaim,
    SecondRoute.formal,
    ThirdRoute.read,
  ]),

  AUDITION_COURSE_CLAIM_FORMAL_COPY: path([
    Route.audition,
    FirstRoute.courseclaim,
    SecondRoute.formal,
    ThirdRoute.copy,
  ]),

  AUDITION_COURSE_CLAIM_TRIAL_READ: path([
    Route.audition,
    FirstRoute.courseclaim,
    SecondRoute.trial,
    ThirdRoute.read,
  ]),

  AUDITION_COURSE_CLAIM_TRIAL_COPY: path([
    Route.audition,
    FirstRoute.courseclaim,
    SecondRoute.trial,
    ThirdRoute.copy,
  ]),

  AUDITION_PUBLIC_COURSE: path([Route.audition, FirstRoute.publicCourse]),
  AUDITION_PUBLIC_COURSE_CREATE: path([
    Route.audition,
    FirstRoute.publicCourse,
    SecondRoute.create,
  ]),
  AUDITION_PUBLIC_COURSE_READ: path([
    Route.audition,
    FirstRoute.publicCourse,
    SecondRoute.read,
  ]),
  AUDITION_PUBLIC_COURSE_EDIT: path([
    Route.audition,
    FirstRoute.publicCourse,
    SecondRoute.edit,
  ]),
  AUDITION_BATCH_SCHEDULED_COURSE: path([
    Route.audition,
    FirstRoute.batchscheduledcourse,
  ]),
  AUDITION_BATCH_SCHEDULED_COURSE_CREATE: path([
    Route.audition,
    FirstRoute.batchscheduledcourse,
    SecondRoute.create,
  ]),
  AUDITION_BATCH_SCHEDULED_COURSE_READ: path([
    Route.audition,
    FirstRoute.batchscheduledcourse,
    SecondRoute.read,
  ]),
  AUDITION_BATCH_SCHEDULED_COURSE_COPY: path([
    Route.audition,
    FirstRoute.batchscheduledcourse,
    SecondRoute.copy,
  ]),
  AUDITION_REALTIME_COURSE: path([Route.audition, FirstRoute.realtimecourse]),
  AUDITION_REALTIME_COURSE_READ: path([
    Route.audition,
    FirstRoute.realtimecourse,
    SecondRoute.read,
  ]),
  AUDITION_REALTIME_COURSE_CAROUSEL: path([
    Route.audition,
    FirstRoute.realtimecoursecarousel,
  ]),
  AUDITION_OMS_COURSE: path([Route.audition, FirstRoute.omscourse]),
  AUDITION_NOW_COURSE: path([Route.audition, FirstRoute.nowcourse]),
  AUDITION_NOW_COURSE_READ: path([
    Route.audition,
    FirstRoute.nowcourse,
    SecondRoute.read,
  ]),
  AUDITION_NOW_COURSE_EDIT: path([
    Route.audition,
    FirstRoute.nowcourse,
    SecondRoute.edit,
  ]),
  AUDITION_NOW_COURSE_FEEDBACK: path([
    Route.audition,
    FirstRoute.nowcourse,
    SecondRoute.feedback,
  ]),

  AUDITION_COURSETAG: path([Route.audition, FirstRoute.coursetag]),
  AUDITION_COURSE_FEEDBACK: path([
    Route.audition,
    FirstRoute.course,
    SecondRoute.feedback,
  ]),
  AUDITION_COURSE_REVIEW: path([Route.audition, FirstRoute.coursereview]),
  AUDITION_COURSE_REVIEW_READ: path([
    Route.audition,
    FirstRoute.coursereview,
    SecondRoute.reviewdetail,
  ]),
  AUDITION_LEAVE: path([Route.audition, FirstRoute.leave]),
  AUDITION_LEAVE_READ: path([
    Route.audition,
    FirstRoute.leave,
    SecondRoute.read,
  ]),
  ELECTRONICCOMMERCE: path([Route.electroniccommerce]),
  ELECTRONICCOMMERCE_COMMODITY: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_SKU: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.sku,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_SKU_CREATE: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.sku,
    ThirdRoute.create,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_SKU_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.sku,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_SKU_ATTRIBUTES: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.sku,
    ThirdRoute.attribute,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_SPU: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.spu,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_SPU_CREATE: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.spu,
    ThirdRoute.create,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_SPU_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.spu,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_PLAN: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.plan,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_PLAN_CREATE: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.plan,
    ThirdRoute.create,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_PLAN_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.plan,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_PROMOTION: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.promotion,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_PROMOTION_CREATE: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.promotion,
    ThirdRoute.create,
  ]),
  ELECTRONICCOMMERCE_COMMODITY_PROMOTION_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.commodity,
    SecondRoute.promotion,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_SETTING: path([
    Route.electroniccommerce,
    FirstRoute.setting,
  ]),
  ELECTRONICCOMMERCE_SETTING_PAYMENT: path([
    Route.electroniccommerce,
    FirstRoute.setting,
    SecondRoute.payment,
  ]),
  ELECTRONICCOMMERCE_SETTING_CONTRACT: path([
    Route.electroniccommerce,
    FirstRoute.setting,
    SecondRoute.contract,
  ]),
  ELECTRONICCOMMERCE_SETTING_CONTRACT_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.setting,
    SecondRoute.contract,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_SETTING_LEARNINGBAR_CONTRACT: path([
    Route.electroniccommerce,
    FirstRoute.setting,
    SecondRoute.learningbarcontract,
  ]),
  ELECTRONICCOMMERCE_SETTING_LEARNINGBAR_CONTRACT_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.setting,
    SecondRoute.learningbarcontract,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_SETTING_TUTORIAL_CONTRACT: path([
    Route.electroniccommerce,
    FirstRoute.setting,
    SecondRoute.tutorialcontract,
  ]),
  ELECTRONICCOMMERCE_SETTING_TUTORIAL_CONTRACT_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.setting,
    SecondRoute.tutorialcontract,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_SETTING_TUTORIAL_VIP_CONTRACT: path([
    Route.electroniccommerce,
    FirstRoute.setting,
    SecondRoute.tutorialvipcontract,
  ]),
  ELECTRONICCOMMERCE_SETTING_TUTORIAL_VIP_CONTRACT_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.setting,
    SecondRoute.tutorialvipcontract,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_SETTING_MAIL: path([
    Route.electroniccommerce,
    FirstRoute.setting,
    SecondRoute.mail,
  ]),
  ELECTRONICCOMMERCE_SETTING_TOS: path([
    Route.electroniccommerce,
    FirstRoute.setting,
    SecondRoute.tos,
  ]),
  ELECTRONICCOMMERCE_SETTING_TOS_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.setting,
    SecondRoute.tos,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_SETTING_EXTENSION_CONTRACT: path([
    Route.electroniccommerce,
    FirstRoute.setting,
    SecondRoute.extensioncontract,
  ]),
  ELECTRONICCOMMERCE_SETTING_EXTENSION_CONTRACT_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.setting,
    SecondRoute.extensioncontract,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_ORDER: path([Route.electroniccommerce, FirstRoute.order]),
  ELECTRONICCOMMERCE_ORDER_CREATE: path([
    Route.electroniccommerce,
    FirstRoute.order,
    SecondRoute.create,
  ]),
  ELECTRONICCOMMERCE_ORDER_COPY: path([
    Route.electroniccommerce,
    FirstRoute.order,
    SecondRoute.copy,
  ]),
  ELECTRONICCOMMERCE_ORDER_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.order,
    SecondRoute.edit,
  ]),
  ELECTRONICCOMMERCE_CONTRACTS_EXTENSION_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.order,
    SecondRoute.orderextended,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_CONTRACTS_NOWORDER_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.order,
    SecondRoute.noworder,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_ORDER_TUTORIAL_CREATE: path([
    Route.electroniccommerce,
    FirstRoute.order,
    SecondRoute.ordertutorial,
    ThirdRoute.create,
  ]),
  ELECTRONICCOMMERCE_ORDER_TUTORIAL_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.order,
    SecondRoute.ordertutorial,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_ORDER_SCHOOL_CREATE: path([
    Route.electroniccommerce,
    FirstRoute.order,
    SecondRoute.orderschool,
    ThirdRoute.create,
  ]),
  ELECTRONICCOMMERCE_ORDER_SCHOOL_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.order,
    SecondRoute.orderschool,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_ORDER_LEARNING_BAR_CREATE: path([
    Route.electroniccommerce,
    FirstRoute.order,
    SecondRoute.orderlearningbar,
    ThirdRoute.create,
  ]),
  ELECTRONICCOMMERCE_ORDER_LEARNING_BAR_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.order,
    SecondRoute.orderlearningbar,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_ORDER_EXTENDED_CREATE: path([
    Route.electroniccommerce,
    FirstRoute.order,
    SecondRoute.orderextended,
    ThirdRoute.create,
  ]),
  ELECTRONICCOMMERCE_ORDER_EXTENDED_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.order,
    SecondRoute.orderextended,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_NOW_ORDER_CREATE: path([
    Route.electroniccommerce,
    FirstRoute.order,
    SecondRoute.noworder,
    ThirdRoute.create,
  ]),
  ELECTRONICCOMMERCE_NOW_ORDER_EDIT: path([
    Route.electroniccommerce,
    FirstRoute.order,
    SecondRoute.noworder,
    ThirdRoute.edit,
  ]),
  ELECTRONICCOMMERCE_REFUND_ASSISTANCE: path([
    Route.electroniccommerce,
    FirstRoute.refundAssistance,
  ]),
  TEACHER_MANAGEMENT: path([Route.teachersmanagement]),
  TEACHER_MANAGEMENT_LIST: path([
    Route.teachersmanagement,
    FirstRoute.teacherlist,
  ]),

  TEACHER_MANAGEMENT_CREATE: path([
    Route.teachersmanagement,
    FirstRoute.teacherlist,
    SecondRoute.create,
  ]),
  TEACHER_MANAGEMENT_EDIT: path([
    Route.teachersmanagement,
    FirstRoute.teacherlist,
    SecondRoute.edit,
  ]),
  TEACHER_MANAGEMENT_VIEW: path([
    Route.teachersmanagement,
    FirstRoute.teacherlist,
    SecondRoute.view,
  ]),
  TEACHER_MANAGEMENT_SCHEDULE: path([
    Route.teachersmanagement,
    FirstRoute.teacherlist,
    SecondRoute.schedule,
  ]),
  TEACHER_MANAGEMENT_TAG: path([
    Route.teachersmanagement,
    FirstRoute.teacherlist,
    SecondRoute.taglist,
  ]),
  TEACHER_MANAGEMENT_MATERIAL: path([
    Route.teachersmanagement,
    FirstRoute.teachermaterial,
  ]),
  TEACHER_INTERVIEW_MANAGEMENT_LIST: path([
    Route.teachersmanagement,
    FirstRoute.teacherinterviewlist,
  ]),
  TEACHER_UNVERIFIED_MANAGEMENT_LIST: path([
    Route.teachersmanagement,
    FirstRoute.teacherunverifiedlist,
  ]),
  TEACHER_SETTING: path([Route.teachersmanagement, FirstRoute.contract]),
  TEACHER_INTERVIEW_MANAGEMENT_EDIT: path([
    Route.teachersmanagement,
    FirstRoute.teacherinterviewlist,
    SecondRoute.applicantView,
  ]),
  TEACHER_UNVERIFIED_MANAGEMENT_EDIT: path([
    Route.teachersmanagement,
    FirstRoute.teacherunverifiedlist,
    SecondRoute.applicantView,
  ]),
  REFERRERS_MANAGEMENT_LIST: path([
    Route.teachersmanagement,
    FirstRoute.referrersList,
  ]),
  TEACHER_MANAGEMENT_MATERIAL_SERIES_READ: path([
    Route.teachersmanagement,
    FirstRoute.teachermaterial,
    SecondRoute.seriesread,
  ]),
  TEACHER_MANAGEMENT_MATERIAL_SERIES_CREATE: path([
    Route.teachersmanagement,
    FirstRoute.teachermaterial,
    SecondRoute.seriescreate,
  ]),
  TEACHER_MANAGEMENT_MATERIAL_SERIES_EDIT: path([
    Route.teachersmanagement,
    FirstRoute.teachermaterial,
    SecondRoute.seriesedit,
  ]),
  TEACHER_MANAGEMENT_MATERIAL_RESOURCE_CREATE: path([
    Route.teachersmanagement,
    FirstRoute.teachermaterial,
    SecondRoute.resourcecreate,
  ]),
  TEACHER_MANAGEMENT_MATERIAL_RESOURCE_EDIT: path([
    Route.teachersmanagement,
    FirstRoute.teachermaterial,
    SecondRoute.resourceedit,
  ]),
  TEACHER_MANAGEMENT_MATERIAL_EXAM_EDIT: path([
    Route.teachersmanagement,
    FirstRoute.teachermaterial,
    SecondRoute.examedit,
  ]),
  TEACHER_MANAGEMENT_MATERIAL_HOMEWORK_EDIT: path([
    Route.teachersmanagement,
    FirstRoute.teachermaterial,
    SecondRoute.homeworkedit,
  ]),
  TEACHER_MANAGEMENT_MATERIAL_INTERACTIVE_READ: path([
    Route.teachersmanagement,
    FirstRoute.teachermaterial,
    SecondRoute.interactivelearningread,
  ]),
  TEACHER_MANAGEMENT_MATERIAL_INTERACTIVE_CREATE: path([
    Route.teachersmanagement,
    FirstRoute.teachermaterial,
    SecondRoute.interactivelearningcreate,
  ]),
  TEACHER_MANAGEMENT_MATERIAL_INTERACTIVE_EDIT: path([
    Route.teachersmanagement,
    FirstRoute.teachermaterial,
    SecondRoute.interactivelearningedit,
  ]),
  REPORT: path([Route.report]),
  REPORT_DAILY: path([Route.report, FirstRoute.daily]),
  REPORT_REALTIME: path([Route.report, FirstRoute.realtime]),
  REPORT_PERFORMANCE: path([Route.report, FirstRoute.performance]),
  REPORT_PERFORMANCE_DETAIL: path([
    Route.report,
    FirstRoute.performance,
    SecondRoute.performancedetail,
  ]),
  REPORT_PERFORMANCE_DETAIL_EDIT: path([
    Route.report,
    FirstRoute.performance,
    SecondRoute.performancedetail,
    ThirdRoute.edit,
  ]),
  AUTHORITY: path([Route.authority]),
  AUTHORITY_EMPLOYEE: path([Route.authority, FirstRoute.employee]),
  AUTHORITY_ROLE: path([Route.authority, FirstRoute.role]),
  AUTHORITY_DEPARTMENT: path([Route.authority, FirstRoute.department]),
  FINANCIAL: path([Route.financial]),
  FINANCIAL_SALARY: path([Route.financial, FirstRoute.salary]),
  FINANCIAL_SALARY_OVERVIEW: path([Route.financial, FirstRoute.salaryoverview]),
  FINANCIAL_LABOR_COST_LIST: path([Route.financial, FirstRoute.laborcost]),
  FINANCIAL_PERFORMANCE_EXPENSE_LIST: path([
    Route.financial,
    FirstRoute.performanceexpense,
  ]),
  FINANCIAL_SALARY_DETAIL: path([
    Route.financial,
    FirstRoute.salary,
    SecondRoute.salarydetail,
  ]),
  FINANCIAL_UNEARNED_REVENUE: path([
    Route.financial,
    FirstRoute.unearnedrevenue,
  ]),
  FINANCIAL_UNEARNED_REVENUE_DETAIL: path([
    Route.financial,
    FirstRoute.unearnedrevenue,
    SecondRoute.unearnedrevenuedetail,
  ]),
  TUTORIAL_CENTER: path([Route.tutorialcenter]),
  TUTORIAL_CENTER_FORMAL_COURSE: path([
    Route.tutorialcenter,
    FirstRoute.course,
  ]),
  TUTORIAL_CENTER_FORMAL_COURSE_CREATE: path([
    Route.tutorialcenter,
    FirstRoute.course,
    SecondRoute.create,
  ]),
  TUTORIAL_CENTER_FORMAL_COURSE_READ: path([
    Route.tutorialcenter,
    FirstRoute.course,
    SecondRoute.read,
  ]),
  TUTORIAL_CENTER_FORMAL_COURSE_EDIT: path([
    Route.tutorialcenter,
    FirstRoute.course,
    SecondRoute.edit,
  ]),
  TUTORIAL_CENTER_FORMAL_COURSE_COPY: path([
    Route.tutorialcenter,
    FirstRoute.course,
    SecondRoute.copy,
  ]),
  TUTORIAL_CENTER_FORMAL_COURSE_ONE_: path([
    Route.tutorialcenter,
    FirstRoute.course,
  ]),

  /* OneLink+ start */
  TUTORIAL_CENTER_FORMAL_COURSE_ONE_LINK_PLUS_CREATE: path([
    Route.tutorialcenter,
    FirstRoute.course,
    SecondRoute.onelinkplus,
    ThirdRoute.create,
  ]),
  TUTORIAL_CENTER_FORMAL_COURSE_ONE_LINK_PLUS_READ: path([
    Route.tutorialcenter,
    FirstRoute.course,
    SecondRoute.onelinkplus,
    ThirdRoute.read,
  ]),
  TUTORIAL_CENTER_FORMAL_COURSE_ONE_LINK_PLUS_EDIT: path([
    Route.tutorialcenter,
    FirstRoute.course,
    SecondRoute.onelinkplus,
    ThirdRoute.edit,
  ]),
  TUTORIAL_CENTER_FORMAL_COURSE_ONE_LINK_PLUS_COPY: path([
    Route.tutorialcenter,
    FirstRoute.course,
    SecondRoute.onelinkplus,
    ThirdRoute.copy,
  ]),
  /* OneLink+ end */

  CUSTOMER_TICKET: path([Route.customerticket]),
  CUSTOMER_TICKET_INSTANT_CASE: path([
    Route.customerticket,
    FirstRoute.instantcase,
  ]),
  CUSTOMER_TICKET_MY_CASE: path([Route.customerticket, FirstRoute.mycase]),
  CUSTOMER_TICKET_HISTORY_CASE: path([
    Route.customerticket,
    FirstRoute.historycase,
  ]),
  NOTIFICATION_CENTER: path([
    Route.notification,
    FirstRoute.notificationcenter,
  ]),
  CUSTOMER_TICKET_MEMBER_CHANNEL_MANAGEMENT: path([
    Route.customerticket,
    FirstRoute.memberchannelmanagement,
  ]),
  CUSTOMER_TICKET_MEMBER_CHANNEL_MANAGEMENT_DETAIL: path([
    Route.customerticket,
    FirstRoute.memberchannelmanagement,
    SecondRoute.memberchanneldetail,
  ]),
  MEDAL_CENTRAL: path([Route.badgecentral]),
  MEDAL_CENTRAL_REWARD_REDEMPTION: path([
    Route.badgecentral,
    FirstRoute.rewardshipmentmanagement,
  ]),
  MEDAL_CENTRAL_REWARD_REDEMPTION_EDIT: path([
    Route.badgecentral,
    FirstRoute.rewardshipmentmanagement,
    SecondRoute.edit,
  ]),
  TEXTBOOK_MANAGEMENT: path([Route.badgecentral, FirstRoute.textbookmangement]),
  TEXTBOOK_CREATE: path([
    Route.badgecentral,
    FirstRoute.textbookmangement,
    SecondRoute.create,
  ]),
  TEXTBOOK_READ: path([
    Route.badgecentral,
    FirstRoute.textbookmangement,
    SecondRoute.read,
  ]),
  TEXTBOOK_EDIT: path([
    Route.badgecentral,
    FirstRoute.textbookmangement,
    SecondRoute.edit,
  ]),
  ADVERTISEMENT: path([Route.advertisement]),
  ADVERTISEMENT_LIVE_APP: path([Route.advertisement, FirstRoute.liveapp]),
  ADVERTISEMENT_LIVE_APP_CREATE: path([
    Route.advertisement,
    FirstRoute.liveapp,
    SecondRoute.create,
  ]),
  ADVERTISEMENT_LIVE_APP_EDIT: path([
    Route.advertisement,
    FirstRoute.liveapp,
    SecondRoute.edit,
  ]),
  TEACHER_CONTRACT_SETTING: path([
    Route.teachersmanagement,
    FirstRoute.contract,
    SecondRoute.teacherContract,
  ]),
  TERMINATED_CONTRACT_SETTING: path([
    Route.teachersmanagement,
    FirstRoute.contract,
    SecondRoute.terminatedContract,
  ]),
  ONE_ANSWER: path([Route.oneAnswer]),
  ONE_ANSWER_RECORD: path([Route.oneAnswer, FirstRoute.record]),
  LINE_CHAT_MANAGEMENT: path([Route.lineChatManagement]),
};

export enum Auth {
  // new
  DATA_CENTRAL_SALES_READ = 'data_central_sales:read',
  DATA_CENTRAL_FINANCE_READ = 'data_central_finance:read',
  VISITER_DATA_READ = 'visiter_data:read',
  VISITER_DATA_IMPORT = 'visiter_data:import',
  COURSE_FORMAL_READ = 'course_formal:read',
  COURSE_REVISE_AND_REPAY_CREDIT_EDIT = 'course_revise_and_repay_credit:edit',
  COURSE_FORMAL_DELETE = 'course_formal:delete',
  COURSE_FORMAL_EDIT = 'course_formal:edit',
  COURSE_FORMAL_URL_READ = 'course_formal_url:read',
  COURSE_FORMAL_REVIEW_EDIT = 'course_formal_review:edit',
  COURSE_DATA_EXPORT = 'course_data:export',
  SCHEDULE_CLASSES_COURSE_DATA_EXPORT = 'schedule_classes_course_data:export',
  CLASS_ADMINISTRATOR_COURSE_DATA_EXPORT = 'class_administrator_course_data:export',
  CONSULTANT_COURSE_DATA_EXPORT = 'consultant_course_data:export',
  COURSE_AUDITION_READ = 'course_audition:read',
  COURSE_AUDITION_EDIT = 'course_audition:edit',
  COURSE_AUDITION_DELETE = 'course_audition:delete',
  COURSE_AUDITION_OWN_READ = 'course_audition_own:read',
  COURSE_AUDITION_URL_READ = 'course_audition_url:read',
  COURSE_AUDITION_REVIEW_EDIT = 'course_audition_review:edit',
  COURSE_CREATE = 'course:create',
  AFTER_CLASS_STUDY_READ = 'after_class_study:read',
  AFTER_CLASS_STUDY_CREATE = 'after_class_study:create',
  AFTER_CLASS_STUDY_EDIT = 'after_class_study:edit',
  AFTER_CLASS_STUDY_CANCEL = 'after_class_study:cancel',
  AFTER_CLASS_STUDY_EXPORT = 'after_class_study:export',
  COURSE_OBSERVATION_READ = 'course_observation:read',
  COURSE_OMS_READ = 'course_oms:read',
  COURSE_TAG_READ = 'course_tag:read',
  COURSE_TAG_IMPORT = 'course_tag:import',
  BATCH_SCHEDULED_COURSES_READ = 'batch_scheduled_courses:read',
  BATCH_SCHEDULED_COURSES_CREATE = 'batch_scheduled_courses:create',
  BATCH_SCHEDULED_COURSES_CANCEL = 'batch_scheduled_courses:invalid',
  BATCH_SCHEDULED_COURSES_ALL_CANCEL = 'batch_scheduled_all_courses:cancel',

  COURSE_TAG_DELETE = 'course_tag:delete',
  COURSE_REVIEW_READ = 'course_review:read',
  COURSE_REVIEW_COUNSELOR_READ = 'course_review_counselor:read',
  COURSE_REVIEW_PRELIMINARY_COUNSELOR_EDIT = 'course_review_preliminary_counselor:edit',
  COURSE_REVIEW_SECOND_COUNSELOR_EDIT = 'course_review_second_counselor:edit',
  COURSE_REVIEW_TEACHER_TRAINING_READ = 'course_review_teacher_training:read',
  COURSE_REVIEW_SECOND_TEACHER_TRAINING_EDIT = 'course_review_second_teacher_training:edit',
  COURSE_REVIEW_PRELIMINARY_TEACHER_TRAINING_EDIT = 'course_review_preliminary_teacher_training:edit',
  COURSE_REVIEW_COURSE_SCHEDULING_READ = 'course_review_course_scheduling:read',
  COURSE_REVIEW_PRELIMINARY_COURSE_SCHEDULING_EDIT = 'course_review_preliminary_course_scheduling:edit',
  COURSE_REVIEW_AUDIT_READ = 'course_review_audit:read',
  COURSE_REVIEW_PRELIMINARY_AUDIT_EDIT = 'course_review_preliminary_audit:edit',
  COURSE_REVIEW_SECOND_AUDIT_EDIT = 'course_review_second_audit:edit',
  LEAVE_READ = 'leave:read',
  LEAVE_OWN_READ = 'leave_own:read',
  LEAVE_REMARK_EDIT = 'leave_remark:edit',
  LEAVE_STATUS_EDIT = 'leave_status:edit',
  TUTORIAL_CENTER_AUDITION_BOOK_READ = 'tutorial_center_audition_book:read',
  TUTORIAL_CENTER_AUDITION_SCHEDULE_READ = 'tutorial_center_audition_schedule:read',
  TUTORIAL_CENTER_AUDITION_SCHEDULE_CREATE = 'tutorial_center_audition_schedule:create',
  TUTORIAL_CENTER_AUDITION_SCHEDULE_DELETE = 'tutorial_center_audition_schedule:delete',
  TUTORIAL_CENTER_COURSE_FORMAL_READ = 'tutorial_center_course_formal:read',
  TUTORIAL_CENTER_COURSE_AUDITION_READ = 'tutorial_center_course_audition:read',
  TUTORIAL_CENTER_COURSE_AUDITION_OWN_READ = 'tutorial_center_course_audition_own:read',
  TUTORIAL_CENTER_COURSE_CREATE = 'tutorial_center_course:create',
  TUTORIAL_CENTER_COURSE_FORMAL_EDIT = 'tutorial_center_course_formal:edit',
  TUTORIAL_CENTER_COURSE_AUDITION_EDIT = 'tutorial_center_course_audition:edit',
  TUTORIAL_CENTER_COURSE_FORMAL_DELETE = 'tutorial_center_course_formal:delete',
  TUTORIAL_CENTER_COURSE_AUDITION_DELETE = 'tutorial_center_course_audition:delete',
  TUTORIAL_CENTER_COURSE_FORMAL_URL_READ = 'tutorial_center_course_formal_url:read',
  TUTORIAL_CENTER_COURSE_AUDITION_URL_READ = 'tutorial_center_course_audition_url:read',
  TUTORIAL_CENTER_COURSE_DATA_EXPORT = 'tutorial_center_course_data:export',
  TEACHER_READ = 'teacher:read',
  TEACHER_DETAILS_READ = 'teacher_detail:read',
  TEACHER_SCHEDULE_CREATE = 'teacher_schedule:create',
  TEACHER_SCHEDULE_DELETE = 'teacher_schedule:delete',
  TEACHER_SCHEDULE_LEAVE = 'teacher_schedule:leave',
  TEACHER_SCHEDULE_READ = 'teacher_schedule:read',
  TEACHER_SCHEDULE_CLEAN = 'teacher_schedule:clean',
  TEACHER_UNVERIFIED_READ = 'teacher_unverified:read',
  TEACHER_CONTRACT_EDIT = 'teacher_contract:edit',
  TEACHER_CONTRACT_READ = 'teacher_contract:read',
  TEACHER_CREATE = 'teacher:create',
  TEACHER_EDIT = 'teacher:edit',
  TEACHER_EXPORT = 'teacher:export',
  TEACHER_TALK = 'teacher:talk',
  TEACHER_APPLICATION_READ = 'teacher_application:read',
  TEACHER_APPLICATION_READ_UNVERIFIED = 'teacher_application:readUnverified',
  TEACHER_APPLICATION_EDIT = 'teacher_application:edit',
  TEACHER_APPLICATION_REVIEW_INIT = 'teacher_application:reviewInit',
  TEACHER_APPLICATION_REVIEW_SECOND = 'teacher_application:reviewSecond',
  MATERIAL_READ = 'material:read',
  MATERIAL_CREATE = 'material:create',
  MATERIAL_EDIT = 'material:edit',
  MATERIAL_IMPORT = 'material:import',
  MATERIAL_EXAM = 'material:exam',
  MATERIAL_DELETE = 'material:delete',
  MATERIAL_GAME_READ = 'material_game:read',
  MATERIAL_GAME_CREATE = 'material_game:create',
  MATERIAL_GAME_EDIT = 'material_game:edit',
  MATERIAL_GAME_REMOVE = 'material_game:remove',
  MATERIAL_GAME_EXPORT = 'material_game:export',
  TEACHER_SALARY_READ = 'teacher_salary:read',
  TEACHER_SALARY_EDIT = 'teacher_salary:edit',
  TEACHER_SALARY_FREEZE = 'teacher_salary:freeze',
  TEACHER_SALARY_FREEZE_CANCEL = 'teacher_salary:freezeCancel',
  TEACHER_SALARY_EXPORT = 'teacher_salary:export',
  TEACHER_BONUS_IMPORT = 'teacher_bonus:import',
  SALARY_OVERALL_READ = 'salary_overall:read',
  SALARY_OVERALL_EDIT = 'salary_overall:edit',
  SALARY_OVERALL_EXPORT = 'salary_overall:export',
  LABOR_COST_READ = 'labor_cost:view',
  LABOR_COST_EXPORT = 'labor_cost:export',
  PERFORMANCE_ALL_READ = 'performance_all:read',
  PERFORMANCE_OWN_READ = 'performance_own:read',
  PERFORMANCE_EXPORT = 'performance:export',
  PERFORMANCE_EDIT = 'performance:edit',
  NOW_COURSE_PRE_CLASS_READ = 'now_course_pre_class:read',
  NOW_COURSE_PRE_CLASS_EDIT = 'now_course_pre_class:edit',
  NOW_COURSE_PRE_CLASS_URL_READ = 'now_course_pre_class_url:read',
  NOW_COURSE_FORMAL_READ = 'now_course_formal:read',
  NOW_COURSE_FORMAL_EDIT = 'now_course_formal:edit',
  NOW_COURSE_FORMAL_URL_READ = 'now_course_formal_url:read',
  // old
  CUSTOMER_CUSTOMER = 'customer_customer:read',
  CUSTOMER_CUSTOMER_EDIT = 'customer_customer:edit',
  CUSTOMER_OWN_CUSTOMER_READ = 'customer_own_customer:read',
  CUSTOMER_ALL_CUSTOMER_READ = 'customer_all_customer:read',
  CUSTOMER_SCHEDULE_PREFERENCE_EXPORT = 'customer_schedule_preference:export',
  CUSTOMER_DATA_CREATE = 'customer_data:create',
  CUSTOMER_DATA_IMPORT = 'customer_data:import',
  CUSTOMER_LEARNINGBAR_DATA_CREATE = 'customer_learningbar_data:create',
  CUSTOMER_ORGANIZATION_GROUP_EDIT = 'customer_organization_group:edit',
  CUSTOMER_PAYMENTSTATUS_REACTIVATE_EDIT = 'customer_paymentStatus_reactivate:edit',
  CUSTOMER_NOW_PURCHASABLE_EDIT = 'customer_now_purchasable:edit',
  CUSTOMER_TYPE_READ = 'customer_type:read',
  CUSTOMER_TYPE_EDIT = 'customer_type:edit',
  CUSTOMER_BXID_READ = 'customer_bxid:read',
  CUSTOMER_BXID_EDIT = 'customer_bxid:edit',
  CUSTOMER_ID_READ = 'customer_id:read',
  CUSTOMER_CHILDREN_CREATE = 'customer_children:create',
  CUSTOMER_CHILDREN_READ = 'customer_children:read',
  CUSTOMER_CHILDREN_EDIT = 'customer_children:edit',
  CUSTOMER_COURSE_CREATE = 'customer_course:create',
  CUSTOMER_SALES_READ = 'customer_sales:read',
  CUSTOMER_SALES_EDIT = 'customer_sales:edit',
  CUSTOMER_CONSULTER_READ = 'customer_consulter:read',
  CUSTOMER_CONSULTER_EDIT = 'customer_consulter:edit',
  CUSTOMER_INFO_READ = 'customer_info:read',
  CUSTOMER_INFO_EDIT = 'customer_info:edit',
  CUSTOMER_NOTE_READ = 'customer_note:read',
  CUSTOMER_NOTE_EDIT = 'customer_note:edit',
  CUSTOMER_AVAILABLE_COURSE_READ = 'customer_available_course:read',
  CUSTOMER_AVAILABLE_COURSE_RECORD_READ = 'customer_available_course_record:read',
  CUSTOMER_AVAILABLE_COURSE_RECORD_EDIT = 'customer_available_course_record:edit',
  CUSTOMER_CONTRACT_READ = 'customer_contract:read',
  CUSTOMER_CONTRACT_RECORD_READ = 'customer_contract_record:read',
  CUSTOMER_CONTRACT_RECORD_EDIT = 'customer_contract_record:edit',
  CUSTOMER_BILL_READ = 'customer_bill:read',
  CUSTOMER_BILL_SEND = 'customer_bill:send',

  CUSTOMER_BILL_RECORD_READ = 'customer_bill_record:read',

  CUSTOMER_BILL_EDIT = 'electroniccommerce_order:transfer',
  CUSTOMER_COURSE_READ = 'customer_course:read',
  CUSTOMER_COURSE_EDIT = 'customer_customer:courseTransfer',
  CUSTOMER_TEXTBOOKSET_READ = 'customer_textbookset:read',
  CUSTOMER_TEXTBOOKSET_EDIT = 'customer_textbookset:edit',
  AUDITION_PERIOD_READ = 'audition_period:read',
  AUDITION_PERIOD_CREATE = 'audition_period:create',
  AUDITION_PERIOD_EDIT = 'audition_period:edit',
  AUDITION_PERIOD_DELETE = 'audition_period:delete',
  AUDITION_RESERVATION_READ = 'audition_reservation:read',
  AUDITION_RESERVATION_CREATE = 'audition_reservation:create',
  POINT_MANAGE_READ = 'point_manage:read',
  POINT_MANAGE_EDIT = 'point_manage:edit',
  // ----- new Audition authorization -----
  AUDITION_BOOK_READ = 'audition_book:read',
  AUDITION_SCHEDULE_READ = 'audition_schedule:read',
  AUDITION_SCHEDULE_CREATE = 'audition_schedule:create',
  AUDITION_SCHEDULE_DELETE = 'audition_schedule:delete',
  // ----- new Audition authorization -----
  AUDITION_VIP_PASS_CREATE = 'audition_vipPass:create',
  AUDITION_COURSE_READ = 'audition_course:read',
  AUDITION_COURSE_CREATE = 'audition_course:create',
  AUDITION_COURSE_EDIT = 'audition_course:edit',
  AUDITION_COURSE_LINK = 'audition_course:link',
  AUDITION_COURSE_CANCEL = 'audition_course:cancel',
  AUDITION_PUBLIC_COURSE_READ = 'audition_course:read',
  AUDITION_PUBLIC_COURSE_CREATE = 'audition_course:create',
  AUDITION_PUBLIC_COURSE_EDIT = 'audition_course:edit',
  AUDITION_PUBLIC_COURSE_LINK = 'audition_course:link',
  AUDITION_PUBLIC_COURSE_CANCEL = 'audition_course:cancel',
  AUDITION_COURSE_FEEDBACK = 'audition_course_feedback',
  LEARNING_COACH_READ = 'learning_coach:read',
  // AUDITION_TAG= 'audition_course:cancel',
  ELECTRONICCOMMERCE_COMMODITY_READ = 'electroniccommerce_commodity:read',
  ELECTRONICCOMMERCE_COMMODITY_CREATE = 'electroniccommerce_commodity:create',
  ELECTRONICCOMMERCE_COMMODITY_EDIT = 'electroniccommerce_commodity:edit',
  ELECTRONICCOMMERCE_SETTING_READ = 'electroniccommerce_setting:read',
  ELECTRONICCOMMERCE_SETTING_EDIT = 'electroniccommerce_setting:edit',
  ELECTRONICCOMMERCE_ORDER_READ = 'electroniccommerce_order:read',
  ELECTRONICCOMMERCE_ORDER_EXPORT = 'electroniccommerce_order:export',
  ELECTRONICCOMMERCE_ORDER_CREATE = 'electroniccommerce_order:create',
  ELECTRONICCOMMERCE_ORDER_EDITBEFOREPURCHASE = 'electroniccommerce_order:editBeforePurchase',
  ELECTRONICCOMMERCE_ORDER_EDITAFTERPURCHASE = 'electroniccommerce_order:editAfterPurchase',
  ELECTRONICCOMMERCE_ORDER_CANCEL = 'electroniccommerce_order:cancel',
  ELECTRONICCOMMERCE_ORDER_CLOSE = 'electroniccommerce_order:close',
  ELECTRONICCOMMERCE_ORDER_EMAIL = 'electroniccommerce_order:email',
  ELECTRONICCOMMERCE_CONTRACT_EXTENSION_EMAIL = 'electroniccommerce_contract_extension:email',
  ELECTRONICCOMMERCE_ORDER_INVOICE = 'electroniccommerce_order:invoice',
  ELECTRONICCOMMERCE_ORDER_FUND = 'electroniccommerce_order:fund',
  ELECTRONICCOMMERCE_ORDER_DETAIL_INPUT_TRANSFER = 'electroniccommerce_order_detail:input_transfer',
  ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_CREATE = 'electroniccommerce_learningbar_order:create',
  ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_EDITBEFOREPURCHASE = 'electroniccommerce_learningbar_order:editBeforePurchase',
  ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_EDITAFTERPURCHASE = 'electroniccommerce_learningbar_order:editAfterPurchase',
  ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_CANCEL = 'electroniccommerce_learningbar_order:cancel',
  ELECTRONICCOMMERCE_TUTORIAL_ORDER_CREATE = 'electroniccommerce_tutorial_order:create',
  ELECTRONICCOMMERCE_TUTORIAL_ORDER_EDITBEFOREPURCHASE = 'electroniccommerce_tutorial_order:editBeforePurchase',
  ELECTRONICCOMMERCE_TUTORIAL_ORDER_EDITAFTERPURCHASE = 'electroniccommerce_tutorial_order:editAfterPurchase',
  ELECTRONICCOMMERCE_TUTORIAL_ORDER_CANCEL = 'electroniccommerce_tutorial_order:cancel',
  ELECTRONICCOMMERCE_SCHOOL_ORDER_CREATE = 'electroniccommerce_school_order:create',
  ELECTRONICCOMMERCE_SCHOOL_ORDER_EDITBEFOREPURCHASE = 'electroniccommerce_school_order:editBeforePurchase',
  ELECTRONICCOMMERCE_SCHOOL_ORDER_EDITAFTERPURCHASE = 'electroniccommerce_school_order:editAfterPurchase',
  ELECTRONICCOMMERCE_SCHOOL_ORDER_CANCEL = 'electroniccommerce_school_order:cancel',
  NOW_ORDER_DETAIL_EDIT = 'now_order_detail:edit',
  NOW_ORDER_REFUND_APPLY = 'now_order:refund_apply',
  NOW_ORDER_REFUND_CHECK = 'now_order:refund_check',
  REFUND_ORDER_APPLY = 'refund_order:apply',
  REFUND_ORDER_CREATE = 'refund_order:create',
  REFUND_ORDER_CANCEL = 'refund_order:cancel',
  REFUND_ORDER_CHECK = 'refund_order:check',
  REFUND_ORDER_DETAIL_READ = 'refund_order_detail:read',
  REFUND_ORDER_DETAIL_APPLY_EDIT = 'refund_order_detail_apply:edit',
  REFUND_ORDER_DETAIL_PENDING_EDIT = 'refund_order_detail_pending:edit',
  EXTEND_EXPIRATION_DATE_EDIT = 'extend_expiration_date:edit',
  EXPIRATION_DATE_RECORD_DELETE = 'expiration_date_record:delete', // 已棄用
  AUTHORITY_EMPLOYEE_READ = 'authority_employee:read',
  AUTHORITY_EMPLOYEE_EDIT = 'authority_employee:edit',
  AUTHORITY_ROLE_READ = 'authority_role:read',
  AUTHORITY_ROLE_EDIT = 'authority_role:edit',
  AUTHORITY_DEPARTMENT_READ = 'authority_department:read',
  AUTHORITY_DEPARTMENT_EDIT = 'authority_department:edit',
  REPORT_DAILY_READ = 'report_daily:read',
  REPORT_REALTIME_READ = 'report_realtime:read',
  BADGE_READ = 'badge:read',
  BADGE_EDIT = 'badge:edit',
  BADGE_EXPORT = 'badge:export',
  BADGE_IMPORT = 'badge:import',
  REVENUE_READ = 'revenue:read',
  REVENUE_EXPORT = 'revenue:export',
  DRAWDOWN_IMPORT = 'drawdown:import',
  PERFORMANCE_EXPENSE_READ = 'performance_expense:read',
  PERFORMANCE_EXPENSE_EXPORT = 'performance_expense:export',
  PERFORMANCE_EXPENSE_IMPORT = 'performance_expense:import',
  TEXTBOOK_READ = 'textbook:read',
  TEXTBOOK_READ_INFO = 'textbook:read_info',
  TEXTBOOK_READ_MEMBER = 'textbook:read_member',
  TEXTBOOK_READ_MEMBER_ALL = 'textbook:read_member_all',
  TEXTBOOK_CREATE = 'textbook:create',
  TEXTBOOK_EDIT = 'textbook:edit',
  TEXTBOOK_EXPORT = 'textbook:export',
  TEXTBOOK_IMPORT = 'textbook:import',
  CONTRACT_EXTENSION_RECORD_READ = 'contract_extension_record:read',
  CONTRACT_EXTENSION_CREATE = 'contract_extension:create',
  CONTRACT_EXTENSION_EDIT = 'contract_extension:edit',
  CONTRACT_EXTENSION_TERMINATE = 'contract_extension:terminate',
  ORDER_EXTENSION_CREATE = 'order_extension:create',
  PARENT_MESSAGE_READ = 'parent_message:read',
  PARENT_MESSAGE_EDIT = 'parent_message:edit',
  PARENT_MESSAGE_SWITCH = 'parent_message:switch',
  PARENT_TICKETS_NEW = 'parent_tickets_new:create',
  PARENT_TICKETS_HISTORY_READ = 'parent_tickets_history:read',
  PARENT_TICKETS_HISTORY_EDIT = 'parent_tickets_history:edit',
  NOTIFY_TICKETS_NEW = 'notify:tickets_new',
  LEAVE_NOTIFICATION_READ = 'leave_notification:read',
  ASSIGNED_STUDENTS_LEAVE_NOTIFICATION_READ = 'assigned_students_leave_notification:read',
  TICKETS_READ = 'tickets:read',
  TICKETS_EDIT = 'tickets:edit',
  TICKETS_SUBSTITUTE_IMPORT = 'tickets_substitute:import',
  HISTORY_TICKETS_READ = 'history_tickets:read',
  HISTORY_TICKETS_EXPORT = 'history_tickets:export',
  LINE_CHAT_READ = 'line_chat:read',
  LINE_CHAT_CONVERSATION_EDIT = 'line_chat_conversation:edit',
  LINE_CHAT_ACCEPT = 'line_chat:accept',
  LINE_CHAT_TRANSFER = 'line_chat:transfer',
  LINE_CHAT_USER_EDIT = 'line_chat_user:edit',
  LINE_CHAT_CHANNEL_BIND = 'line_chat_channel:bind',
  COURSE_CLAIM_READ = 'course_claim:read',
  COURSE_CLAIM_CREATE = 'course_claim:create',
  COURSE_CLAIM_DELETE = 'course_claim:delete',
  INVOICE_ISSUE_SUPPLEMENTARY = 'invoice:issue_supplementary',
  INVOICE_EDIT = 'invoice:edit',
  COURSE_RESTREAMING_TRIGGER = 'course_restreaming:trigger',

  COURSE_AUDITION_CLAIM_READ = 'course_audition_claim:read',
  COURSE_AUDITION_CLAIM_CREATE = 'course_audition_claim:create',
  COURSE_AUDITION_CLAIM_DELETE = 'course_audition_claim:delete',
}
export const AuthName = {
  [Auth.CUSTOMER_OWN_CUSTOMER_READ]: '該人員負責會員',
  [Auth.CUSTOMER_ALL_CUSTOMER_READ]: '所有會員',
  [Auth.CUSTOMER_SCHEDULE_PREFERENCE_EXPORT]: '匯出排課偏好',
  [Auth.CUSTOMER_DATA_CREATE]: '新增會員',
  [Auth.CUSTOMER_LEARNINGBAR_DATA_CREATE]: '新增會員',
  [Auth.CUSTOMER_ORGANIZATION_GROUP_EDIT]: '機構/班級',
  [Auth.CUSTOMER_NOW_PURCHASABLE_EDIT]: '小堂數開關',
  [Auth.CUSTOMER_PAYMENTSTATUS_REACTIVATE_EDIT]: '已補款/重新啟用/停用',
  [Auth.CUSTOMER_TYPE_READ]: '會員類型',
  [Auth.CUSTOMER_TYPE_EDIT]: '會員類型',
  [Auth.CUSTOMER_DATA_IMPORT]: '檔次匯入',
  [Auth.CUSTOMER_BXID_READ]: 'Bitrix ID',
  [Auth.CUSTOMER_BXID_EDIT]: 'Bitrix ID',
  [Auth.CUSTOMER_ID_READ]: 'OneClub ID',
  [Auth.CUSTOMER_CHILDREN_CREATE]: '新增成員',
  [Auth.CUSTOMER_CHILDREN_READ]: '成員',
  [Auth.CUSTOMER_CHILDREN_EDIT]: '編輯成員',
  [Auth.CUSTOMER_COURSE_CREATE]: '新增課程',
  [Auth.CUSTOMER_SALES_READ]: '顧問',
  [Auth.CUSTOMER_SALES_EDIT]: '編輯顧問',
  [Auth.CUSTOMER_CONSULTER_READ]: '輔導老師',
  [Auth.CUSTOMER_CONSULTER_EDIT]: '編輯輔導老師',
  [Auth.CUSTOMER_INFO_READ]: '基本資料',
  [Auth.CUSTOMER_INFO_EDIT]: '編輯基本資料',
  [Auth.CUSTOMER_NOTE_READ]: '備註資料',
  [Auth.CUSTOMER_NOTE_EDIT]: '編輯備註資料',
  [Auth.CUSTOMER_AVAILABLE_COURSE_READ]: '可用堂數資料',
  [Auth.CUSTOMER_AVAILABLE_COURSE_RECORD_READ]: '堂數異動紀錄',
  [Auth.CUSTOMER_AVAILABLE_COURSE_RECORD_EDIT]: '編輯堂數異動紀錄',
  [Auth.CUSTOMER_CONTRACT_READ]: '合約到期日',
  [Auth.CUSTOMER_CONTRACT_RECORD_READ]: '合約到期日異動紀錄',
  [Auth.CUSTOMER_CONTRACT_RECORD_EDIT]: '編輯合約到期日異動紀錄',
  [Auth.CUSTOMER_BILL_READ]: '訂單紀錄',
  [Auth.CUSTOMER_BILL_SEND]: '寄送通知信/簡訊',
  [Auth.CUSTOMER_BILL_RECORD_READ]: '通知寄送紀錄',
  [Auth.CUSTOMER_BILL_EDIT]: '轉移訂單',
  [Auth.CUSTOMER_COURSE_READ]: '課程紀錄',
  [Auth.CUSTOMER_COURSE_EDIT]: '轉移課程',
  [Auth.CUSTOMER_TEXTBOOKSET_READ]: '講義套數',
  [Auth.CUSTOMER_TEXTBOOKSET_EDIT]: '講義套數',
  [Auth.AUDITION_BOOK_READ]: '可預約時段',
  [Auth.AUDITION_SCHEDULE_READ]: '已預約時段',
  [Auth.AUDITION_SCHEDULE_CREATE]: '新增試聽',
  [Auth.AUDITION_SCHEDULE_DELETE]: '取消試聽',
  [Auth.AUDITION_COURSE_READ]: '所有試聽課程',
  // NEW
  [Auth.DATA_CENTRAL_SALES_READ]: '銷售數據',
  [Auth.DATA_CENTRAL_FINANCE_READ]: '財務數據',
  [Auth.VISITER_DATA_READ]: '聯絡人資料',
  [Auth.VISITER_DATA_IMPORT]: '匯入檔次',
  [Auth.AUDITION_VIP_PASS_CREATE]: '快速通道',
  [Auth.LEARNING_COACH_READ]: '學習教練',
  [Auth.COURSE_FORMAL_READ]: '課程詳情',
  [Auth.COURSE_REVISE_AND_REPAY_CREDIT_EDIT]: '變更堂數/系統補扣堂',
  [Auth.COURSE_AUDITION_READ]: '所有課程詳情',
  [Auth.COURSE_AUDITION_OWN_READ]: '該人員負責課程',
  [Auth.COURSE_CREATE]: '新增課程',
  [Auth.COURSE_FORMAL_EDIT]: '編輯課程',
  [Auth.COURSE_AUDITION_EDIT]: '編輯課程',
  [Auth.COURSE_FORMAL_DELETE]: '取消課程',
  [Auth.COURSE_AUDITION_DELETE]: '取消課程',
  [Auth.COURSE_FORMAL_URL_READ]: '上課連結',
  [Auth.COURSE_AUDITION_URL_READ]: '上課連結',
  [Auth.COURSE_FORMAL_REVIEW_EDIT]: '重新審核異常完課',
  [Auth.COURSE_AUDITION_REVIEW_EDIT]: '重新審核異常完課',
  [Auth.COURSE_DATA_EXPORT]: '定期匯出課程紀錄',
  [Auth.SCHEDULE_CLASSES_COURSE_DATA_EXPORT]: '排課用',
  [Auth.CLASS_ADMINISTRATOR_COURSE_DATA_EXPORT]: '班務行政用',
  [Auth.CONSULTANT_COURSE_DATA_EXPORT]: '輔導用',

  [Auth.NOW_COURSE_PRE_CLASS_READ]: '查看說明課',
  [Auth.NOW_COURSE_PRE_CLASS_EDIT]: '編輯說明課',
  [Auth.NOW_COURSE_PRE_CLASS_URL_READ]: '說明課上課連結',
  [Auth.NOW_COURSE_FORMAL_READ]: '查看正式課',
  [Auth.NOW_COURSE_FORMAL_EDIT]: '編輯正式課',
  [Auth.NOW_COURSE_FORMAL_URL_READ]: '正式課上課連結',

  [Auth.AFTER_CLASS_STUDY_READ]: '課程詳情',
  [Auth.AFTER_CLASS_STUDY_CREATE]: '新增課程',
  [Auth.AFTER_CLASS_STUDY_EDIT]: '編輯課程',
  [Auth.AFTER_CLASS_STUDY_CANCEL]: '取消課程',
  [Auth.AFTER_CLASS_STUDY_EXPORT]: '匯出',
  [Auth.COURSE_OBSERVATION_READ]: '課程詳情',
  [Auth.COURSE_TAG_READ]: '課程標籤',
  [Auth.COURSE_TAG_IMPORT]: '匯入',
  [Auth.COURSE_TAG_DELETE]: '刪除',
  [Auth.BATCH_SCHEDULED_COURSES_READ]: '連續課程列表',
  [Auth.BATCH_SCHEDULED_COURSES_CREATE]: '新增連續課程',
  [Auth.BATCH_SCHEDULED_COURSES_CANCEL]: '取消邀約',
  [Auth.BATCH_SCHEDULED_COURSES_ALL_CANCEL]: '取消所有課程',
  [Auth.COURSE_REVIEW_READ]: '查看',
  [Auth.COURSE_REVIEW_COUNSELOR_READ]: '查看',
  [Auth.COURSE_REVIEW_PRELIMINARY_COUNSELOR_EDIT]: '編輯初審',
  [Auth.COURSE_REVIEW_SECOND_COUNSELOR_EDIT]: '編輯複審',
  [Auth.COURSE_REVIEW_TEACHER_TRAINING_READ]: '查看',
  [Auth.COURSE_REVIEW_SECOND_TEACHER_TRAINING_EDIT]: '編輯複審',
  [Auth.COURSE_REVIEW_PRELIMINARY_TEACHER_TRAINING_EDIT]: '編輯初審',
  [Auth.COURSE_REVIEW_COURSE_SCHEDULING_READ]: '查看',
  [Auth.COURSE_REVIEW_PRELIMINARY_COURSE_SCHEDULING_EDIT]: '編輯初審',
  [Auth.COURSE_REVIEW_AUDIT_READ]: '查看',
  [Auth.COURSE_REVIEW_PRELIMINARY_AUDIT_EDIT]: '編輯初審',
  [Auth.COURSE_REVIEW_SECOND_AUDIT_EDIT]: '編輯複審',
  [Auth.LEAVE_READ]: '所有假單',
  [Auth.LEAVE_OWN_READ]: '該人員負責學員',
  [Auth.LEAVE_REMARK_EDIT]: '編輯備註',
  [Auth.LEAVE_STATUS_EDIT]: '處理狀態及取消假單',
  [Auth.TUTORIAL_CENTER_AUDITION_BOOK_READ]: '可預約時段',
  [Auth.TUTORIAL_CENTER_AUDITION_SCHEDULE_READ]: '已預約時段',
  [Auth.TUTORIAL_CENTER_AUDITION_SCHEDULE_CREATE]: '新增試聽',
  [Auth.TUTORIAL_CENTER_AUDITION_SCHEDULE_DELETE]: '取消試聽',
  [Auth.TUTORIAL_CENTER_COURSE_FORMAL_READ]: '查看正式課',
  [Auth.TUTORIAL_CENTER_COURSE_AUDITION_READ]: '查看所有試聽課',
  [Auth.TUTORIAL_CENTER_COURSE_AUDITION_OWN_READ]: '查看該人員所有試聽課',
  [Auth.TUTORIAL_CENTER_COURSE_CREATE]: '新增課程',
  [Auth.TUTORIAL_CENTER_COURSE_FORMAL_EDIT]: '編輯正式課',
  [Auth.TUTORIAL_CENTER_COURSE_AUDITION_EDIT]: '編輯試聽課',
  [Auth.TUTORIAL_CENTER_COURSE_FORMAL_DELETE]: '取消課程',
  [Auth.TUTORIAL_CENTER_COURSE_AUDITION_DELETE]: '取消課程',
  [Auth.TUTORIAL_CENTER_COURSE_FORMAL_URL_READ]: '上課連結',
  [Auth.TUTORIAL_CENTER_COURSE_AUDITION_URL_READ]: '上課連結',
  [Auth.TUTORIAL_CENTER_COURSE_DATA_EXPORT]: '匯出課程紀錄',
  [Auth.TEACHER_READ]: '教師列表',
  [Auth.TEACHER_DETAILS_READ]: '教師資料',
  [Auth.TEACHER_CREATE]: '新增教師',
  [Auth.TEACHER_EDIT]: '編輯教師',
  [Auth.TEACHER_EXPORT]: '匯出報表',
  [Auth.TEACHER_TALK]: '發起對話',
  [Auth.TEACHER_SCHEDULE_CREATE]: '新增排班',
  [Auth.TEACHER_SCHEDULE_DELETE]: '清除排班',
  [Auth.TEACHER_SCHEDULE_LEAVE]: '請假',
  [Auth.TEACHER_SCHEDULE_READ]: '請假紀錄',
  [Auth.TEACHER_SCHEDULE_CLEAN]: '修正30天內行事曆',
  [Auth.MATERIAL_READ]: '教材資源',
  [Auth.TEACHER_APPLICATION_EDIT]: '編輯教師',
  [Auth.TEACHER_CONTRACT_EDIT]: '合約',
  [Auth.TEACHER_CONTRACT_READ]: '合約',
  [Auth.TEACHER_APPLICATION_READ]: '教師面試',
  [Auth.TEACHER_APPLICATION_READ_UNVERIFIED]: '未驗證教師列表',
  [Auth.MATERIAL_CREATE]: '新增',
  [Auth.MATERIAL_EDIT]: '編輯',
  [Auth.MATERIAL_IMPORT]: '匯入',
  [Auth.MATERIAL_EXAM]: '派測驗',
  [Auth.MATERIAL_DELETE]: '刪除',
  [Auth.TEACHER_SALARY_READ]: '老師薪資',
  [Auth.TEACHER_SALARY_EDIT]: '編輯',
  [Auth.TEACHER_SALARY_FREEZE]: '凍結',
  [Auth.TEACHER_SALARY_FREEZE_CANCEL]: '取消凍結',
  [Auth.TEACHER_SALARY_EXPORT]: '匯出報表',
  [Auth.TEACHER_BONUS_IMPORT]: '匯入本月加給',
  [Auth.SALARY_OVERALL_READ]: '薪資總覽',
  [Auth.SALARY_OVERALL_EDIT]: '編輯',
  [Auth.SALARY_OVERALL_EXPORT]: '匯出報表',
  [Auth.LABOR_COST_READ]: '勞務成本表',
  [Auth.LABOR_COST_EXPORT]: '匯出報表',
  [Auth.PERFORMANCE_ALL_READ]: '所有業績',
  [Auth.PERFORMANCE_OWN_READ]: '該人員負責的業績',
  [Auth.PERFORMANCE_EXPORT]: '匯出報表',
  [Auth.PERFORMANCE_EDIT]: '業績詳情',
  [Auth.BADGE_READ]: '出貨查看',
  [Auth.BADGE_EDIT]: '出貨編輯',
  [Auth.REVENUE_READ]: '預收收入表',
  [Auth.REVENUE_EXPORT]: '匯出報表',
  [Auth.DRAWDOWN_IMPORT]: '動撥申請書',
  [Auth.PERFORMANCE_EXPENSE_READ]: '業務成本表',
  [Auth.PERFORMANCE_EXPENSE_EXPORT]: '匯出報表',
  [Auth.PERFORMANCE_EXPENSE_IMPORT]: '匯入報表',
  [Auth.ELECTRONICCOMMERCE_ORDER_EDITBEFOREPURCHASE]: '編輯未付款訂單',
  [Auth.ELECTRONICCOMMERCE_ORDER_EDITAFTERPURCHASE]: '編輯已付款訂單',
  [Auth.ELECTRONICCOMMERCE_ORDER_READ]: '查看',
  [Auth.ELECTRONICCOMMERCE_ORDER_EXPORT]: '匯出',
  [Auth.ELECTRONICCOMMERCE_ORDER_CREATE]: '新增訂單',
  [Auth.ELECTRONICCOMMERCE_ORDER_CANCEL]: '取消未付款訂單',
  [Auth.ELECTRONICCOMMERCE_ORDER_CLOSE]: '結案訂單',
  [Auth.ELECTRONICCOMMERCE_ORDER_EMAIL]: '寄送通知信',
  [Auth.ELECTRONICCOMMERCE_CONTRACT_EXTENSION_EMAIL]: '寄送展延通知信',
  [Auth.ELECTRONICCOMMERCE_ORDER_INVOICE]: '登錄發票',
  [Auth.ELECTRONICCOMMERCE_ORDER_FUND]: '請款',
  [Auth.ELECTRONICCOMMERCE_ORDER_DETAIL_INPUT_TRANSFER]: '登錄轉帳資訊',
  [Auth.ELECTRONICCOMMERCE_SETTING_READ]: '查看設定',
  [Auth.ELECTRONICCOMMERCE_SETTING_EDIT]: '編輯設定',
  [Auth.ELECTRONICCOMMERCE_COMMODITY_READ]: '查看商品',
  [Auth.ELECTRONICCOMMERCE_COMMODITY_EDIT]: '編輯商品',
  [Auth.ELECTRONICCOMMERCE_COMMODITY_CREATE]: '新增商品',
  [Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_CREATE]: '新增訂單',
  [Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_EDITBEFOREPURCHASE]:
    '編輯未付款訂單',
  [Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_EDITAFTERPURCHASE]:
    '編輯已付款訂單',
  [Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_CANCEL]: '取消未付款訂單',
  [Auth.ELECTRONICCOMMERCE_TUTORIAL_ORDER_CREATE]: '新增訂單',
  [Auth.ELECTRONICCOMMERCE_TUTORIAL_ORDER_EDITBEFOREPURCHASE]: '編輯未付款訂單',
  [Auth.ELECTRONICCOMMERCE_TUTORIAL_ORDER_EDITAFTERPURCHASE]: '編輯已付款訂單',
  [Auth.ELECTRONICCOMMERCE_TUTORIAL_ORDER_CANCEL]: '取消未付款訂單',
  [Auth.ELECTRONICCOMMERCE_SCHOOL_ORDER_CREATE]: '新增訂單',
  [Auth.ELECTRONICCOMMERCE_SCHOOL_ORDER_EDITBEFOREPURCHASE]: '編輯未付款訂單',
  [Auth.ELECTRONICCOMMERCE_SCHOOL_ORDER_EDITAFTERPURCHASE]: '編輯已付款訂單',
  [Auth.ELECTRONICCOMMERCE_SCHOOL_ORDER_CANCEL]: '取消未付款訂單',
  [Auth.REFUND_ORDER_APPLY]: '申請退款',
  [Auth.REFUND_ORDER_CREATE]: '確認退款',
  [Auth.REFUND_ORDER_CANCEL]: '取消退款',
  [Auth.REFUND_ORDER_CHECK]: '完成退款',
  [Auth.REFUND_ORDER_DETAIL_READ]: '退款詳情',
  [Auth.REFUND_ORDER_DETAIL_APPLY_EDIT]: '退款詳情（退款申請中）',
  [Auth.REFUND_ORDER_DETAIL_PENDING_EDIT]: '退款詳情（待退款）',
  [Auth.EXTEND_EXPIRATION_DATE_EDIT]: '延展合約',
  [Auth.TEXTBOOK_READ]: '列表',
  [Auth.TEXTBOOK_READ_INFO]: '詳情',
  [Auth.TEXTBOOK_READ_MEMBER]: '所有會員',
  [Auth.TEXTBOOK_READ_MEMBER_ALL]: '負責的會員',
  [Auth.TEXTBOOK_CREATE]: '新增',
  [Auth.TEXTBOOK_EDIT]: '編輯',
  [Auth.TEXTBOOK_EXPORT]: '匯出',
  [Auth.TEXTBOOK_IMPORT]: '匯入',
  [Auth.CONTRACT_EXTENSION_RECORD_READ]: '異動紀錄',
  [Auth.CONTRACT_EXTENSION_CREATE]: '新增展延訂單',
  [Auth.CONTRACT_EXTENSION_EDIT]: '編輯展延訂單',
  [Auth.CONTRACT_EXTENSION_TERMINATE]: '終止展延訂單',
  [Auth.ORDER_EXTENSION_CREATE]: '新增手動展延訂單',
  [Auth.NOW_ORDER_DETAIL_EDIT]: '編輯小堂數訂單',
  [Auth.NOW_ORDER_REFUND_APPLY]: '申請小堂數退款',
  [Auth.NOW_ORDER_REFUND_CHECK]: '完成小堂數訂單退款',
  [Auth.PARENT_MESSAGE_READ]: '查看',
  [Auth.PARENT_MESSAGE_EDIT]: '對話',
  [Auth.PARENT_MESSAGE_SWITCH]: '開關聊天室',
  [Auth.PARENT_TICKETS_NEW]: '新增',
  [Auth.PARENT_TICKETS_HISTORY_READ]: '查看',
  [Auth.PARENT_TICKETS_HISTORY_EDIT]: '編輯',
  [Auth.NOTIFY_TICKETS_NEW]: '新案件通知',
  [Auth.LEAVE_NOTIFICATION_READ]: '所有請假通知',
  [Auth.ASSIGNED_STUDENTS_LEAVE_NOTIFICATION_READ]: '該人員負責的學生請假通知',
  [Auth.TICKETS_READ]: '查看',
  [Auth.TICKETS_EDIT]: '編輯',
  [Auth.HISTORY_TICKETS_READ]: '查看',
  [Auth.HISTORY_TICKETS_EXPORT]: '匯出',
  [Auth.LINE_CHAT_READ]: '查看',
  [Auth.LINE_CHAT_CONVERSATION_EDIT]: '對話',
  [Auth.LINE_CHAT_ACCEPT]: '開啟談話',
  [Auth.LINE_CHAT_TRANSFER]: '轉交',
  [Auth.LINE_CHAT_USER_EDIT]: '邀請＆移除人員',
  [Auth.LINE_CHAT_CHANNEL_BIND]: '綁定&解綁',
  [Auth.COURSE_CLAIM_READ]: '查看',
  [Auth.COURSE_CLAIM_CREATE]: '新增課程',
  [Auth.COURSE_CLAIM_DELETE]: '刪除課程',
  [Auth.INVOICE_ISSUE_SUPPLEMENTARY]: '開立補繳款發票',
  [Auth.INVOICE_EDIT]: '編輯發票',
  [Auth.COURSE_RESTREAMING_TRIGGER]: '重啟直播',
  [Auth.COURSE_AUDITION_CLAIM_READ]: '查看',
  [Auth.COURSE_AUDITION_CLAIM_CREATE]: '新增課程',
  [Auth.COURSE_AUDITION_CLAIM_DELETE]: '刪除課程',
  [Auth.MATERIAL_GAME_READ]: '查看',
  [Auth.MATERIAL_GAME_CREATE]: '新增',
  [Auth.MATERIAL_GAME_EDIT]: '編輯',
  [Auth.MATERIAL_GAME_REMOVE]: '上/下架',
  [Auth.MATERIAL_GAME_EXPORT]: '匯出',
  [Auth.TICKETS_SUBSTITUTE_IMPORT]: '匯入代理人名單',
} as const;

// #region 會員管理權限
export const dataCentralSalesRead = [Auth.DATA_CENTRAL_SALES_READ];
export const dataCentralFinanceRead = [Auth.DATA_CENTRAL_FINANCE_READ];
export const visiterDataRead = [Auth.VISITER_DATA_READ];
export const visiterDataEdit = [Auth.VISITER_DATA_IMPORT];
export const customerDataRead = [
  Auth.CUSTOMER_ALL_CUSTOMER_READ,
  Auth.CUSTOMER_OWN_CUSTOMER_READ,
  Auth.CUSTOMER_SCHEDULE_PREFERENCE_EXPORT,
];
export const customerCreate = [
  Auth.CUSTOMER_DATA_CREATE,
  Auth.CUSTOMER_LEARNINGBAR_DATA_CREATE,
];
export const customerLearningbarCreate = [
  Auth.CUSTOMER_LEARNINGBAR_DATA_CREATE,
];
export const customerDataEdit = [
  Auth.CUSTOMER_DATA_CREATE,
  Auth.CUSTOMER_DATA_IMPORT,
];
export const customerLearningbarEdit = [...customerLearningbarCreate];
export const customerAccountRead = [
  Auth.CUSTOMER_BXID_READ,
  Auth.CUSTOMER_ID_READ,
  Auth.CUSTOMER_SALES_READ,
  Auth.CUSTOMER_CONSULTER_READ,
  Auth.CUSTOMER_TYPE_READ,
];
export const customerAccountEdit = [
  Auth.CUSTOMER_CHILDREN_CREATE,
  Auth.CUSTOMER_CHILDREN_EDIT,
  Auth.CUSTOMER_COURSE_CREATE,
  Auth.CUSTOMER_SALES_EDIT,
  Auth.CUSTOMER_CONSULTER_EDIT,
  Auth.CUSTOMER_BXID_EDIT,
  Auth.CUSTOMER_TYPE_EDIT,
  Auth.CUSTOMER_ORGANIZATION_GROUP_EDIT,
  Auth.CUSTOMER_PAYMENTSTATUS_REACTIVATE_EDIT,
  Auth.CUSTOMER_NOW_PURCHASABLE_EDIT,
];
export const customerInfoRead = [Auth.CUSTOMER_INFO_READ];
export const customerInfoEdit = [Auth.CUSTOMER_INFO_EDIT];
export const customerNoteRead = [Auth.CUSTOMER_NOTE_READ];
export const customerNoteEdit = [Auth.CUSTOMER_NOTE_EDIT];
export const customerAvailableCourseRead = [
  Auth.CUSTOMER_AVAILABLE_COURSE_READ,
  Auth.CUSTOMER_AVAILABLE_COURSE_RECORD_READ,
  Auth.CUSTOMER_CONTRACT_READ,
  Auth.CUSTOMER_CONTRACT_RECORD_READ,
];
export const customerAvailableCourseEdit = [
  Auth.CUSTOMER_AVAILABLE_COURSE_RECORD_EDIT,
  Auth.CUSTOMER_CONTRACT_RECORD_EDIT,
];
export const customerBillRead = [
  Auth.CUSTOMER_BILL_READ,
  Auth.CUSTOMER_BILL_RECORD_READ,
];
export const customerBillEdit = [
  Auth.CUSTOMER_BILL_EDIT,
  Auth.CUSTOMER_BILL_SEND,
];
export const customerCourseRead = [Auth.CUSTOMER_COURSE_READ];
export const customerCourseEdit = [Auth.CUSTOMER_COURSE_EDIT];
export const customerTextbooksetRead = [Auth.CUSTOMER_TEXTBOOKSET_READ];
export const customerTextbooksetEdit = [Auth.CUSTOMER_TEXTBOOKSET_EDIT];
export const customerDetail = [
  ...customerAccountRead,
  ...customerInfoRead,
  ...customerNoteRead,
  // TODO: 缺成員權限
  ...customerBillRead,
  ...customerBillEdit,
  ...customerAvailableCourseRead,
];
// #endregion
// #region 課堂管理權限
// new
export const auditionLearningCoach = [Auth.LEARNING_COACH_READ];
export const auditionVipPass = [Auth.AUDITION_VIP_PASS_CREATE];
export const auditionRead = [
  Auth.AUDITION_BOOK_READ,
  Auth.AUDITION_SCHEDULE_READ,
];
export const auditionEdit = [
  Auth.AUDITION_SCHEDULE_CREATE,
  Auth.AUDITION_SCHEDULE_DELETE,
];
export const formalCourseRead = [
  Auth.COURSE_FORMAL_READ,
  Auth.COURSE_FORMAL_URL_READ,
  Auth.COURSE_FORMAL_REVIEW_EDIT,
];
export const formalCourseEdit = [
  Auth.COURSE_CREATE,
  Auth.COURSE_FORMAL_EDIT,
  Auth.COURSE_FORMAL_DELETE,
  Auth.COURSE_REVISE_AND_REPAY_CREDIT_EDIT,
];
export const formalCourseCreate = [Auth.COURSE_CREATE, Auth.COURSE_FORMAL_READ];
export const courseRead = [
  Auth.COURSE_FORMAL_READ,
  Auth.COURSE_AUDITION_READ,
  Auth.COURSE_AUDITION_OWN_READ,
];
export const courseCreate = [Auth.COURSE_CREATE];
export const courseEdit = [Auth.COURSE_FORMAL_EDIT, Auth.COURSE_AUDITION_EDIT];
export const courseAuditionRead = [
  Auth.COURSE_AUDITION_READ,
  Auth.COURSE_AUDITION_OWN_READ,
  Auth.COURSE_AUDITION_URL_READ,
  Auth.COURSE_AUDITION_REVIEW_EDIT,
];
export const nowCourseFormalRead = [
  Auth.NOW_COURSE_FORMAL_READ,
  Auth.NOW_COURSE_FORMAL_URL_READ,
];
export const nowCourseFormalEdit = [Auth.NOW_COURSE_FORMAL_EDIT];
export const nowCoursePreClassRead = [
  Auth.NOW_COURSE_PRE_CLASS_READ,
  Auth.NOW_COURSE_PRE_CLASS_URL_READ,
];
export const nowCoursePreClassEdit = [Auth.NOW_COURSE_PRE_CLASS_EDIT];

export const realtimeCourseRead = [Auth.COURSE_OBSERVATION_READ];

export const courseFormalAuditionRead = [
  ...formalCourseRead,
  ...courseAuditionRead,
];
export const courseAuditionEdit = [
  Auth.COURSE_AUDITION_EDIT,
  Auth.COURSE_AUDITION_DELETE,
];

export const courseExport = [
  Auth.COURSE_DATA_EXPORT,
  Auth.SCHEDULE_CLASSES_COURSE_DATA_EXPORT,
  Auth.CLASS_ADMINISTRATOR_COURSE_DATA_EXPORT,
  Auth.CONSULTANT_COURSE_DATA_EXPORT,
];
export const triggerPublicReplayLiveStreaming = [
  Auth.COURSE_RESTREAMING_TRIGGER,
];

export const courseDataExport = [Auth.COURSE_DATA_EXPORT];
export const afterClassStudyRead = [
  Auth.AFTER_CLASS_STUDY_READ,
  Auth.AFTER_CLASS_STUDY_EXPORT,
];
export const afterClassStudyEdit = [
  Auth.AFTER_CLASS_STUDY_CREATE,
  Auth.AFTER_CLASS_STUDY_EDIT,
  Auth.AFTER_CLASS_STUDY_CANCEL,
];
export const courseTagRead = [Auth.COURSE_TAG_READ];
export const courseTagEdit = [Auth.COURSE_TAG_IMPORT, Auth.COURSE_TAG_DELETE];
export const batchScheduledCoursesRead = [Auth.BATCH_SCHEDULED_COURSES_READ];
export const batchScheduledCoursesEdit = [
  Auth.BATCH_SCHEDULED_COURSES_CREATE,
  Auth.BATCH_SCHEDULED_COURSES_CANCEL,
  Auth.BATCH_SCHEDULED_COURSES_ALL_CANCEL,
];

// course review permissions
export const courseReviewAllRead = [Auth.COURSE_REVIEW_READ];
export const courseReviewCounselorRead = [Auth.COURSE_REVIEW_COUNSELOR_READ];
export const courseReviewCounselorEdit = [
  Auth.COURSE_REVIEW_PRELIMINARY_COUNSELOR_EDIT,
  Auth.COURSE_REVIEW_SECOND_COUNSELOR_EDIT,
];
export const courseReviewTeacherTrainingRead = [
  Auth.COURSE_REVIEW_TEACHER_TRAINING_READ,
];
export const courseReviewTeacherTrainingEdit = [
  Auth.COURSE_REVIEW_SECOND_TEACHER_TRAINING_EDIT,
  Auth.COURSE_REVIEW_PRELIMINARY_TEACHER_TRAINING_EDIT,
];
export const courseReviewCourseSchedulingRead = [
  Auth.COURSE_REVIEW_COURSE_SCHEDULING_READ,
];
export const courseReviewCourseSchedulingEdit = [
  Auth.COURSE_REVIEW_PRELIMINARY_COURSE_SCHEDULING_EDIT,
];
export const courseReviewAuditRead = [Auth.COURSE_REVIEW_AUDIT_READ];
export const courseReviewAuditEdit = [
  Auth.COURSE_REVIEW_PRELIMINARY_AUDIT_EDIT,
  Auth.COURSE_REVIEW_SECOND_AUDIT_EDIT,
];
export const courseReviewRead = [
  ...courseReviewAllRead,
  ...courseReviewCounselorRead,
  ...courseReviewTeacherTrainingRead,
  ...courseReviewCourseSchedulingRead,
  ...courseReviewAuditRead,
];

export const leaveRead = [Auth.LEAVE_READ, Auth.LEAVE_OWN_READ];
export const leaveEdit = [Auth.LEAVE_REMARK_EDIT, Auth.LEAVE_STATUS_EDIT];

export const auditionCourseRead = [Auth.AUDITION_COURSE_READ];

export const courseClaimRead = [
  Auth.COURSE_CLAIM_READ,
  Auth.COURSE_AUDITION_CLAIM_READ,
];
// new auth
// #endregion
// #region 補習班課程管理權限
export const tutorialCenterAuditionRead = [
  Auth.TUTORIAL_CENTER_AUDITION_BOOK_READ,
  Auth.TUTORIAL_CENTER_AUDITION_SCHEDULE_READ,
];
export const tutorialCenterAuditionEdit = [
  Auth.TUTORIAL_CENTER_AUDITION_SCHEDULE_CREATE,
  Auth.TUTORIAL_CENTER_AUDITION_SCHEDULE_DELETE,
];
export const tutorialCenterFormalCourseRead = [
  Auth.TUTORIAL_CENTER_COURSE_FORMAL_READ,
  Auth.TUTORIAL_CENTER_COURSE_FORMAL_URL_READ,
];
export const tutorialCenterFormalCourseEdit = [
  Auth.TUTORIAL_CENTER_COURSE_CREATE,
  Auth.TUTORIAL_CENTER_COURSE_FORMAL_EDIT,
  Auth.TUTORIAL_CENTER_COURSE_FORMAL_DELETE,
];
export const tutorialCenterCourseDataExport = [
  Auth.TUTORIAL_CENTER_COURSE_DATA_EXPORT,
];
export const tutorialCenterCourseRead = [
  Auth.TUTORIAL_CENTER_COURSE_FORMAL_READ,
];
// #endregion
// #region 訂單管理權限
export const electroniccommerceOrderListRead = [
  Auth.ELECTRONICCOMMERCE_ORDER_READ,
];
export const electroniccommerceOrderListEdit = [
  Auth.ELECTRONICCOMMERCE_ORDER_EXPORT,
  Auth.ELECTRONICCOMMERCE_ORDER_CREATE,
];

export const orderDetailsRead = [
  Auth.REFUND_ORDER_DETAIL_READ,
  Auth.CONTRACT_EXTENSION_RECORD_READ,
];
export const orderDetailsEdit = [
  Auth.ELECTRONICCOMMERCE_ORDER_EDITBEFOREPURCHASE,
  Auth.ELECTRONICCOMMERCE_ORDER_EDITAFTERPURCHASE,
  Auth.ELECTRONICCOMMERCE_ORDER_CANCEL,
  Auth.ELECTRONICCOMMERCE_ORDER_CLOSE,
  Auth.ELECTRONICCOMMERCE_ORDER_EMAIL,
  Auth.ELECTRONICCOMMERCE_CONTRACT_EXTENSION_EMAIL,
  Auth.ELECTRONICCOMMERCE_ORDER_INVOICE,
  Auth.ELECTRONICCOMMERCE_ORDER_FUND,
  Auth.ELECTRONICCOMMERCE_ORDER_DETAIL_INPUT_TRANSFER,
  Auth.REFUND_ORDER_APPLY,
  Auth.REFUND_ORDER_CREATE,
  Auth.REFUND_ORDER_CANCEL,
  Auth.REFUND_ORDER_CHECK,
  Auth.REFUND_ORDER_DETAIL_APPLY_EDIT,
  Auth.REFUND_ORDER_DETAIL_PENDING_EDIT,
  Auth.CONTRACT_EXTENSION_CREATE,
  Auth.CONTRACT_EXTENSION_EDIT,
  Auth.CONTRACT_EXTENSION_TERMINATE,
  Auth.ORDER_EXTENSION_CREATE,
  Auth.NOW_ORDER_DETAIL_EDIT,
  Auth.NOW_ORDER_REFUND_APPLY,
  Auth.NOW_ORDER_REFUND_CHECK,
  Auth.INVOICE_ISSUE_SUPPLEMENTARY,
  Auth.INVOICE_EDIT,
];
export const electroniccommerceLearningbarOrderListEdit = [
  Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_CREATE,
  Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_EDITBEFOREPURCHASE,
  Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_EDITAFTERPURCHASE,
  Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_CANCEL,
];
export const electroniccommerceTutorialOrderListEdit = [
  Auth.ELECTRONICCOMMERCE_TUTORIAL_ORDER_CREATE,
  Auth.ELECTRONICCOMMERCE_TUTORIAL_ORDER_EDITBEFOREPURCHASE,
  Auth.ELECTRONICCOMMERCE_TUTORIAL_ORDER_EDITAFTERPURCHASE,
  Auth.ELECTRONICCOMMERCE_TUTORIAL_ORDER_CANCEL,
];
export const electroniccommerceSchoolOrderListEdit = [
  Auth.ELECTRONICCOMMERCE_SCHOOL_ORDER_CREATE,
  Auth.ELECTRONICCOMMERCE_SCHOOL_ORDER_EDITBEFOREPURCHASE,
  Auth.ELECTRONICCOMMERCE_SCHOOL_ORDER_EDITAFTERPURCHASE,
  Auth.ELECTRONICCOMMERCE_SCHOOL_ORDER_CANCEL,
];
export const electroniccommerceCommodityRead = [
  Auth.ELECTRONICCOMMERCE_COMMODITY_READ,
];
export const electroniccommerceCommodityEdit = [
  Auth.ELECTRONICCOMMERCE_COMMODITY_CREATE,
  Auth.ELECTRONICCOMMERCE_COMMODITY_EDIT,
];
export const electroniccommerceCommodity = [
  Auth.ELECTRONICCOMMERCE_COMMODITY_READ,
  Auth.ELECTRONICCOMMERCE_COMMODITY_CREATE,
  Auth.ELECTRONICCOMMERCE_COMMODITY_EDIT,
];
export const electroniccommerceSettingRead = [
  Auth.ELECTRONICCOMMERCE_SETTING_READ,
];
export const electroniccommerceSettingEdit = [
  Auth.ELECTRONICCOMMERCE_SETTING_EDIT,
];
export const electroniccommerceSetting = [
  Auth.ELECTRONICCOMMERCE_SETTING_READ,
  Auth.ELECTRONICCOMMERCE_SETTING_EDIT,
];

export const electroniccommerceOrder = [
  Auth.ELECTRONICCOMMERCE_ORDER_READ,
  Auth.ELECTRONICCOMMERCE_ORDER_EXPORT,
  Auth.ELECTRONICCOMMERCE_ORDER_CREATE,
  Auth.ELECTRONICCOMMERCE_ORDER_EDITBEFOREPURCHASE,
  Auth.ELECTRONICCOMMERCE_ORDER_EDITAFTERPURCHASE,
  Auth.ELECTRONICCOMMERCE_ORDER_CANCEL,
  Auth.ELECTRONICCOMMERCE_ORDER_CLOSE,
  Auth.ELECTRONICCOMMERCE_ORDER_EMAIL,
  Auth.ELECTRONICCOMMERCE_CONTRACT_EXTENSION_EMAIL,
  Auth.ELECTRONICCOMMERCE_ORDER_INVOICE,
  Auth.ELECTRONICCOMMERCE_ORDER_FUND,
  Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_CREATE,
  Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_EDITBEFOREPURCHASE,
  Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_EDITAFTERPURCHASE,
  Auth.ELECTRONICCOMMERCE_LEARNINGBAR_ORDER_CANCEL,
  Auth.REFUND_ORDER_APPLY,
  Auth.REFUND_ORDER_CREATE,
  Auth.REFUND_ORDER_CANCEL,
  Auth.REFUND_ORDER_CHECK,
  Auth.REFUND_ORDER_DETAIL_READ,
];
// #endregion
// #region 權限管理權限
export const authorityEmployee = [
  Auth.AUTHORITY_EMPLOYEE_READ,
  Auth.AUTHORITY_EMPLOYEE_EDIT,
];

export const authorityRole = [
  Auth.AUTHORITY_ROLE_READ,
  Auth.AUTHORITY_ROLE_EDIT,
];

export const authorityDepartment = [
  Auth.AUTHORITY_DEPARTMENT_READ,
  Auth.AUTHORITY_DEPARTMENT_EDIT,
];
// #endregion

// #region 教務管理權限

export const teacherTalk = [Auth.TEACHER_TALK];
export const teacherListRead = [
  Auth.TEACHER_READ,
  Auth.TEACHER_EXPORT,
  Auth.TEACHER_TALK,
  Auth.TEACHER_DETAILS_READ,
  Auth.TEACHER_SCHEDULE_READ,
];

export const teacherListEdit = [
  Auth.TEACHER_CREATE,
  Auth.TEACHER_EDIT,
  Auth.TEACHER_SCHEDULE_CREATE,
  Auth.TEACHER_SCHEDULE_DELETE,
  Auth.TEACHER_SCHEDULE_LEAVE,
  Auth.TEACHER_SCHEDULE_CLEAN,
];
export const teacherMaterialRead = [Auth.MATERIAL_READ];
export const teacherContractEdit = [Auth.TEACHER_CONTRACT_EDIT];
export const teacherContractRead = [Auth.TEACHER_CONTRACT_READ];
export const teacherMaterialEdit = [
  Auth.MATERIAL_CREATE,
  Auth.MATERIAL_EDIT,
  Auth.MATERIAL_IMPORT,
  Auth.MATERIAL_DELETE,
  Auth.MATERIAL_EXAM,
];
export const teacherMaterialGameRead = [Auth.MATERIAL_GAME_READ];
export const teacherMaterialGameEdit = [
  Auth.MATERIAL_GAME_CREATE,
  Auth.MATERIAL_GAME_EDIT,
  Auth.MATERIAL_GAME_REMOVE,
  Auth.MATERIAL_GAME_EXPORT,
];
export const teacherApplicationRead = [Auth.TEACHER_APPLICATION_READ];
export const teacherApplicationEdit = [
  Auth.TEACHER_APPLICATION_REVIEW_INIT,
  Auth.TEACHER_APPLICATION_REVIEW_SECOND,
];

export const teacherApplicationReadUnverified = [
  Auth.TEACHER_APPLICATION_READ_UNVERIFIED,
];
// #endregion

// #region 統計報表權限
// 業績瀏覽 - 查看
export const reportPerformanceRead = [
  Auth.PERFORMANCE_ALL_READ,
  Auth.PERFORMANCE_OWN_READ,
  Auth.PERFORMANCE_EXPORT,
];
// 業績瀏覽 - 編輯
export const reportPerformanceEdit = [Auth.PERFORMANCE_EDIT];
// #endregion
// #region 統計報表權限
export const reportDaily = [Auth.REPORT_DAILY_READ];
export const reportRealtime = [Auth.REPORT_REALTIME_READ];
// #endregion

// #region 財務管理權限
export const teacherSalaryRead = [Auth.TEACHER_SALARY_READ];
export const teacherSalaryEdit = [
  Auth.TEACHER_SALARY_EDIT,
  Auth.TEACHER_SALARY_FREEZE,
  Auth.TEACHER_SALARY_FREEZE_CANCEL,
  Auth.TEACHER_SALARY_EXPORT,
  Auth.TEACHER_BONUS_IMPORT,
];
export const salaryOverallRead = [Auth.SALARY_OVERALL_READ];
export const salaryOverallEdit = [
  Auth.SALARY_OVERALL_EDIT,
  Auth.SALARY_OVERALL_EXPORT,
];
export const laborCostRead = [Auth.LABOR_COST_READ];
export const laborCostExport = [Auth.LABOR_COST_EXPORT];

export const revenueRead = [Auth.REVENUE_READ];
export const revenueEdit = [Auth.REVENUE_EXPORT];
export const drawdownImport = [Auth.DRAWDOWN_IMPORT];
export const performanceExpenseRead = [Auth.PERFORMANCE_EXPENSE_READ];
export const performanceExpenseEdit = [
  Auth.PERFORMANCE_EXPENSE_EXPORT,
  Auth.PERFORMANCE_EXPENSE_IMPORT,
];
// #endregion

// #region 獎章中心權限
export const badgeRead = [Auth.BADGE_READ];
export const badgeEdit = [Auth.BADGE_EDIT];

export const textbookRead = [Auth.TEXTBOOK_READ];

export const textbookCreate = [Auth.TEXTBOOK_CREATE];

export const textbookReadInfo = [Auth.TEXTBOOK_READ_INFO];

export const textbookEdit = [Auth.TEXTBOOK_EDIT];

export const textbookOverrallRead = [
  Auth.TEXTBOOK_READ,
  Auth.TEXTBOOK_READ_INFO,
  Auth.TEXTBOOK_READ_MEMBER,
  Auth.TEXTBOOK_READ_MEMBER_ALL,
];

export const textbookOverrallEdit = [
  Auth.TEXTBOOK_CREATE,
  Auth.TEXTBOOK_EDIT,
  Auth.TEXTBOOK_EXPORT,
  Auth.TEXTBOOK_IMPORT,
];

// #endregion

// #region 聊天室設定

export const parentMessageRead = [Auth.PARENT_MESSAGE_READ];
export const parentMessageEdit = [Auth.PARENT_MESSAGE_EDIT];
export const parentMessageSwitch = [Auth.PARENT_MESSAGE_SWITCH];

export const parentTicketsNew = [Auth.PARENT_TICKETS_NEW];

export const parentTicketsHistoryRead = [Auth.PARENT_TICKETS_HISTORY_READ];
export const parentTicketsHistoryEdit = [Auth.PARENT_TICKETS_HISTORY_EDIT];

export const parentMessageAllEdit = [
  Auth.PARENT_MESSAGE_EDIT,
  Auth.PARENT_MESSAGE_SWITCH,
];
export const notificationTicketManagement = [Auth.NOTIFY_TICKETS_NEW];
export const notificationLeaveManagement = [
  Auth.LEAVE_NOTIFICATION_READ,
  Auth.ASSIGNED_STUDENTS_LEAVE_NOTIFICATION_READ,
];
export const ticketsRead = [Auth.TICKETS_READ];
export const ticketsEdit = [Auth.TICKETS_EDIT];
export const myCaseEdit = [Auth.TICKETS_SUBSTITUTE_IMPORT];
export const historyTicketsRead = [Auth.HISTORY_TICKETS_READ];
export const historyTicketsExport = [Auth.HISTORY_TICKETS_EXPORT];

// #endregion

// #region Line 聊天室
export const lineChatRead = [Auth.LINE_CHAT_READ];
export const lineChatEdit = [
  Auth.LINE_CHAT_CONVERSATION_EDIT,
  Auth.LINE_CHAT_ACCEPT,
  Auth.LINE_CHAT_TRANSFER,
  Auth.LINE_CHAT_USER_EDIT,
  Auth.LINE_CHAT_CHANNEL_BIND,
];
// #endregion
